import React, { useState, useContext, Fragment, useRef } from "react";
import { createContext } from "react";
import clienteAxios from "../config/axios";
import { AlertContext } from "./AlertContext";
import { Toast } from "primereact/toast";

const ServiciosContext = createContext();
const { Provider, Consumer } = ServiciosContext;

function ServiciosProvider({ children }) {
  const [numeroTelefono, guardarNumeroTelefono] = useState(null);
  const [valBusqueda, guardarValBusqueda] = useState(null);
  const [rows, setRows] = useState(null);
  const [afiliado, guardarAfiliado] = useState(null);
  const [titular, guardarTitular] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [numeroIndicativo, guardarNumeroIndicativo] = useState(null);
  const [servicio, guardarServicio] = useState(null);
  const [servicioModificado, guardarServicioModificado] = useState(null);
  const [tripulacionModificada, guardarTripulacionModificada] = useState(null);
  const [despachos, guardarDespachos] = useState(null);
  const [tripulaciones, guardarTripulaciones] = useState(null);
  const [tripulacionesCerradas, guardarTripulacionesCerradas] = useState(null);
  const [tripulacion, guardarTripulacion] = useState(null);
  const [resumen, guardarResumen] = useState({
    rojoTotales: 0,
    rojoPendientes: 0,
    verdeTotales: 0,
    verdePendientes: 0,
    amarilloTotales: 0,
    amarilloPendientes: 0,
    azulTotales: 0,
    azulPendientes: 0,
    fucciaTotales: 0,
    fucciaPendientes: 0,
    blancoTotales: 0,
    blancoPendientes: 0,
    negroTotales: 0,
    negroPendientes: 0,
    naranjaTotales: 0,
    naranjaPendientes: 0,
    celesteTotales: 0,
    celestePendientes: 0,
  });
  const [estadoTomado, setEstadoTomado] = useState(null);
  const [demorados, guardarDemorados] = useState(null);
  const [tripulacionModo, guardarTripulacionModo] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState(null);
  const [servicioSeleccionado, guardarServicioSeleccionado] = useState(null);
  const [cierreEmergencias, guardarCierreEmergencias] = useState(null);
  const [cierreFuccia, guardarCierreFuccia] = useState(null);
  const [cierreMedicamentos, guardarCierreMedicamentos] = useState(null);
  const [chipData, setChipData] = useState([]);
  const [tomaAzul, guardarTomaAzul] = useState(null);
  const [tomaNaranja, guardarTomaNaranja] = useState(null);
  const [openModalAzul, setOpenModalAzul] = useState(false);
  const [openModalFuccia, setOpenModalFuccia] = useState(false);
  const [openModalNaranja, setOpenModalNaranja] = useState(false);
  const [traslados, guardarTraslados] = useState(null);
  const [eventos, guardarEventos] = useState(null);
  const [notas, setNotas] = useState(null);
  const [notaVisible, setNotaVisible] = useState(false);
  const [nota, setNota] = useState(null);
  const [refrescar, setRefrescar] = useState(null);
  const [movilMarcado, setMovilMarcado] = useState(null);
  const [movilesDerivados, guardarMovilesDerivados] = useState(null);
  const [serviciosFinalizados, guardarServiciosFinalizados] = useState(null);
  const [serviciosAnulados, guardarServiciosAnulados] = useState(null);
  const [trasladosServicios, guardarTrasladosServicios] = useState(null);
  const [trasladoServicio, guardarTrasladoServicio] = useState(null);
  const [filtrosListadosServicios, guardarFiltrosListadosServicios] = useState(
    null
  );
  const [serviciosFinalizadosExcel, setServiciosFinalizadosExcel] = useState(
    null
  );
  const [serviciosAnuladosExcel, setServiciosAnuladosExcel] = useState(null);
  const [valorizacion, guardarValorizacion] = useState(null);
  const [cantidadServicios, setCantidadServicios] = useState(0);
  const toast = useRef(null);

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const capturarTelefonoPaciente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/anura/obtenernumerotelefono",
        datos
      );
      let data = respuesta.data.numero;
      guardarNumeroTelefono(data);
      guardarValBusqueda(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarTitular = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/buscartitular",
        datos
      );
      let data = respuesta.data;
      guardarTitular(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const buscarValorizacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/buscarvalorizacion",
        datos
      );
      let data = respuesta.data;
      guardarValorizacion(data);
    } catch (error) {
      guardarValorizacion(null);
      console.log(error.response);
    }
  };

  const obtenerCantidadServiciosTitular = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenercantidadserviciostitular",
        datos
      );
      let data = respuesta.data;
      setCantidadServicios(data);
    } catch (error) {
      setCantidadServicios(0);
      console.log(error.response);
    }
  };

  const obtenerDatosPacientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatosafiliados",
        datos
      );
      let data = respuesta.data;

      //console.log(data)

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.nombre + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
          coseguroDiurnoPediatrico: data[i].coseguroDiurnoPediatrico,
          coseguroNocturnoPediatrico: data[i].coseguroNocturnoPediatrico,
          coseguroDiurnoAdulto: data[i].coseguroDiurnoAdulto,
          coseguroNocturnoAdulto: data[i].coseguroNocturnoAdulto,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDatosTitulares = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatostitulares",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.localidad + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerDatosConvenios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerdatosconvenios",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          id_titular: data[i].id_titular,
          nombre_razonsocial: data[i].nombre_razonsocial,
          domicilio:
            data[i].domicilio + " ( " + data[i].localidades.localidad + " ) ",
          telefono1: data[i].telefono1,
          numeroDocumento: data[i].numerodocumento,
          plan: data[i].planes.nombre,
          localidad: data[i].localidades.localidad,
          provincia: data[i].localidades.provincia,
          id_grupo: data[i].id_grupo,
          grupo: data[i].grupos.nombre,
          planGrupo:
            data[i].planes.nombre + " ( " + data[i].grupos.nombre + " ) ",
          habilitado: data[i].habilitado,
          id_plan: data[i].id_plan_facturacion,
        });
      }

      setRows(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const agregarNuevoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnuevoservicio",
        datos
      );
      let data = respuesta.data;
      mensajeAlerta(respuesta.data.cartel, "success");
      guardarNumeroIndicativo(data.indicativo);
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarNuevoServicioPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnuevoserviciopendiente",
        datos
      );
      mensajeAlerta(respuesta.data.cartel, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const verificarEstadoMoviles = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/verificarestadomoviles"
      );
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServiciosPendientes = async () => {
    let datos = {
      zona: localStorage.getItem("zona"),
      color: localStorage.getItem("colores"),
      movil: localStorage.getItem("moviles"),
    };

    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciospendientes",
        datos
      );
      let data = respuesta.data;

      const local = [];
      let dTomado;
      let dPasado;
      let dSalida;
      let dLlegada;
      let d2;
      let newDate;
      let rojoTotales = 0;
      let rojoPendientes = 0;
      let amarilloTotales = 0;
      let amarilloPendientes = 0;
      let verdeTotales = 0;
      let verdePendientes = 0;
      let azulTotales = 0;
      let azulPendientes = 0;
      let fucciaTotales = 0;
      let fucciaPendientes = 0;
      let blancoTotales = 0;
      let blancoPendientes = 0;
      let negroTotales = 0;
      let negroPendientes = 0;
      let naranjaTotales = 0;
      let naranjaPendientes = 0;
      let celesteTotales = 0;
      let celestePendientes = 0;

      for (var i = 0; i < data.length; i++) {
        dTomado = data[i].ftomado ? data[i].ftomado : null;
        dPasado = data[i].fpasado ? data[i].fpasado : null;
        dSalida = data[i].fsalida ? data[i].fsalida : null;
        dLlegada = data[i].fllegada ? data[i].fllegada : null;
        d2 = new Date();
        newDate = new Date(d2);
        newDate.setHours(d2.getHours());

        local.push({
          id: data[i].id,
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          colorInicio: data[i].colorInicio,
          grupo:
            data[i].grupos.nombre === "CONVENIOS" ||
            data[i].grupos.nombre === "COLEGIOS"
              ? data[i].grupos.nombre + " / " + data[i].nombreTitular
              : data[i].grupos.nombre,
          grupoId: data[i].grupos.id,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          localidad: data[i].localidad,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          planId: data[i].planes.id_codigo,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          fueraZona: data[i].fueraZona,
          reclamado: data[i].reclamado,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
          tripulacionId:
            data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
          movilId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.movilId
              : data[i].derivadoId !== null
              ? data[i].derivadoId
              : null,
          tomado:
            dTomado !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dTomado) + " " + diff_minutes(dTomado, d2)
                : formatDateTime(dTomado)
              : null,
          pasado:
            dPasado !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dPasado) + " " + diff_minutes(dPasado, d2)
                : formatDateTime(dPasado)
              : null,
          salida:
            dSalida !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dSalida) + " " + diff_minutes(dSalida, d2)
                : formatDateTime(dSalida)
              : null,
          llegada:
            dLlegada !== null
              ? data[i].estado === "TOMADO" ||
                data[i].estado === "PASADO" ||
                data[i].estado === "SALIDA"
                ? formatDateTime(dLlegada) + " " + diff_minutes(dLlegada, d2)
                : formatDateTime(dLlegada)
              : null,

          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,

          usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
          usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
          usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
          usuarioLlegada: data[i].usuarioLlegada
            ? data[i].usuarioLlegada
            : null,

          //Tripulacion

          medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          telefonoTripulacion:
            data[i].tripulaciones && data[i].tripulaciones.telefonos
              ? data[i].tripulaciones.telefonos.nombre
              : null,
          // Traslados Servicios
          trasladoServicio:
            data[i].trasladoServicio !== null ? data[i].trasladoServicio : null,
          // Traslados Programados
          traslados: data[i].traslados !== null ? data[i].traslados : null,
          // Eventos Programados
          eventos: data[i].eventos !== null ? data[i].eventos : null,
          // Laborales
          laborales: data[i].laborales !== null ? data[i].laborales : null,
          recepcionApp:
            data[i].recepcionApp !== null ? data[i].recepcionApp : null,
        });

        //console.log(local)

        // Inicializo Resumen

        if (data[i].color === "ROJO") {
          rojoTotales = rojoTotales + 1;
          if (data[i].estado === "TOMADO") {
            rojoPendientes = rojoPendientes + 1;
          }
        }

        if (data[i].color === "AMARILLO") {
          amarilloTotales = amarilloTotales + 1;
          if (data[i].estado === "TOMADO") {
            amarilloPendientes = amarilloPendientes + 1;
          }
        }

        if (data[i].color === "VERDE") {
          verdeTotales = verdeTotales + 1;
          if (data[i].estado === "TOMADO") {
            verdePendientes = verdePendientes + 1;
          }
        }

        if (data[i].color === "AZUL") {
          azulTotales = azulTotales + 1;
          if (data[i].estado === "TOMADO") {
            azulPendientes = azulPendientes + 1;
          }
        }

        if (data[i].color === "FUCCIA") {
          fucciaTotales = fucciaTotales + 1;
          if (data[i].estado === "TOMADO") {
            fucciaPendientes = fucciaPendientes + 1;
          }
        }

        if (data[i].color === "BLANCO") {
          blancoTotales = blancoTotales + 1;
          if (data[i].estado === "TOMADO") {
            blancoPendientes = blancoPendientes + 1;
          }
        }

        if (data[i].color === "NEGRO") {
          negroTotales = negroTotales + 1;
          if (data[i].estado === "TOMADO") {
            negroPendientes = negroPendientes + 1;
          }
        }

        if (data[i].color === "NARANJA") {
          naranjaTotales = naranjaTotales + 1;
          if (data[i].estado === "TOMADO") {
            naranjaPendientes = naranjaPendientes + 1;
          }
        }

        if (data[i].color === "CELESTE") {
          celesteTotales = celesteTotales + 1;
          if (data[i].estado === "TOMADO") {
            celestePendientes = celestePendientes + 1;
          }
        }
      }

      guardarResumen({
        rojoTotales,
        rojoPendientes,
        amarilloTotales,
        amarilloPendientes,
        verdeTotales,
        verdePendientes,
        azulTotales,
        azulPendientes,
        fucciaTotales,
        fucciaPendientes,
        blancoTotales,
        blancoPendientes,
        negroTotales,
        negroPendientes,
        naranjaTotales,
        naranjaPendientes,
        celesteTotales,
        celestePendientes,
      });

      guardarDespachos(local);
      setEstadoTomado(false);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerservicio",
        datos
      );
      let data = respuesta.data;

      //console.log(data)
      guardarServicioModificado({
        ...data,
        motivoConsultaNombre: data.motivosConsultas.nombre,
        motivoConsultaCodigoColor:
          data.motivosConsultas.id_color === 1
            ? "#000000"
            : data.motivosConsultas.id_color === 2
            ? "#FFFFFF"
            : data.motivosConsultas.id_color === 3
            ? "#FFFF00"
            : data.motivosConsultas.id_color === 4
            ? "#008F39"
            : data.motivosConsultas.id_color === 5
            ? "#FF0000"
            : data.motivosConsultas.id_color === 6
            ? "#0000FF"
            : data.motivosConsultas.id_color === 7
            ? "#E30052"
            : data.motivosConsultas.id_color === 8
            ? "#FF8000"
            : data.motivosConsultas.id_color === 12
            ? "#00FFFF"
            : null,

        motivoConsultaColor:
          data.motivosConsultas.id_color === 1
            ? "NEGRO"
            : data.motivosConsultas.id_color === 2
            ? "BLANCO"
            : data.motivosConsultas.id_color === 3
            ? "AMARILLO"
            : data.motivosConsultas.id_color === 4
            ? "VERDE"
            : data.motivosConsultas.id_color === 5
            ? "ROJO"
            : data.motivosConsultas.id_color === 6
            ? "AZUL"
            : data.motivosConsultas.id_color === 7
            ? "FUCCIA"
            : data.motivosConsultas.id_color === 8
            ? "NARANJA"
            : data.motivosConsultas.id_color === 12
            ? "CELESTE"
            : null,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServicioFinalizado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciofinalizado",
        datos
      );
      let data = respuesta.data;

      //console.log(data)
      guardarServicioModificado({
        ...data,
        motivoConsultaNombre: data.motivosConsultas.nombre,
        motivoConsultaCodigoColor:
          data.motivosConsultas.id_color === 1
            ? "#000000"
            : data.motivosConsultas.id_color === 2
            ? "#FFFFFF"
            : data.motivosConsultas.id_color === 3
            ? "#FFFF00"
            : data.motivosConsultas.id_color === 4
            ? "#008F39"
            : data.motivosConsultas.id_color === 5
            ? "#FF0000"
            : data.motivosConsultas.id_color === 6
            ? "#0000FF"
            : data.motivosConsultas.id_color === 7
            ? "#E30052"
            : data.motivosConsultas.id_color === 8
            ? "#FF8000"
            : data.motivosConsultas.id_color === 12
            ? "#00FFFF"
            : null,

        motivoConsultaColor:
          data.motivosConsultas.id_color === 1
            ? "NEGRO"
            : data.motivosConsultas.id_color === 2
            ? "BLANCO"
            : data.motivosConsultas.id_color === 3
            ? "AMARILLO"
            : data.motivosConsultas.id_color === 4
            ? "VERDE"
            : data.motivosConsultas.id_color === 5
            ? "ROJO"
            : data.motivosConsultas.id_color === 6
            ? "AZUL"
            : data.motivosConsultas.id_color === 7
            ? "FUCCIA"
            : data.motivosConsultas.id_color === 8
            ? "NARANJA"
            : data.motivosConsultas.id_color === 12
            ? "CELESTE"
            : null,
        diagnosticoNombre: data.diagnosticos.nombre,
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  function diff_minutes(dt2, dt1) {
    let d1 = new Date(dt2);
    let d11 = new Date(d1);
    d11.setHours(d1.getHours() + 3);

    var diff = (d11.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  function tarifaPami(datos) {
    const { color, tomado, demora, feriados } = datos;

    let tarifa = 0;

    var allDate = tomado.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");

    let dArr = newDate.split("/");
    let semana = dArr[2] + "/" + dArr[1] + "/" + dArr[0];

    let diaSemana = new Date(semana).getDay();
    const index = feriados.findIndex((feriado) => feriado.fecha === newDate);
    let time1 = newTime.split(":");
    let hora = Number(time1[0]);
    const nocturno = hora >= 19 || hora < 7;

    /*
    console.log(newDate);
    console.log(newTime);
    console.log(feriados);
    console.log(diaSemana)
    */

    let tarifa1Verde = 2215.35;
    let tarifa2Verde = 2658.42;
    let tarifa3Verde = 3190.1;
    let tarifa4Verde = 3101.49;
    let tarifa5Verde = 3721.79;
    let tarifa6Verde = 3721.79;
    let tarifa7Verde = 3190.1;

    if (color === "VERDE") {
      if (demora > 360) {
        if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
              tarifa = tarifa1Verde;
            } else {
              tarifa = tarifa2Verde;
            }
          }
        } else {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            tarifa = tarifa1Verde;
          }
        }
      } else {
        if (demora <= 120) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Verde;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Verde;
              } else {
                tarifa = tarifa5Verde;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Verde;
            } else {
              tarifa = tarifa4Verde;
            }
          }
        } else {
          if (demora > 120 && demora <= 180) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa3Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa2Verde;
                } else {
                  tarifa = tarifa3Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa3Verde;
              } else {
                tarifa = tarifa2Verde;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Verde;
                } else {
                  tarifa = tarifa2Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                tarifa = tarifa1Verde;
              }
            }
          }
        }
      }
    }

    let tarifa1Amarillo = 6087.65;
    let tarifa2Amarillo = 7305.18;
    let tarifa3Amarillo = 8766.22;
    let tarifa4Amarillo = 8522.71;
    let tarifa5Amarillo = 10227.25;
    let tarifa6Amarillo = 10227.25;
    let tarifa7Amarillo = 8766.22;

    if (color === "AMARILLO") {
      if (demora > 180) {
        if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
              tarifa = tarifa1Verde;
            } else {
              tarifa = tarifa2Verde;
            }
          }
        } else {
          if (nocturno) {
            tarifa = tarifa2Verde;
          } else {
            tarifa = tarifa1Verde;
          }
        }
      } else {
        if (demora <= 45) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Amarillo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Amarillo;
              } else {
                tarifa = tarifa5Amarillo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Amarillo;
            } else {
              tarifa = tarifa4Amarillo;
            }
          }
        } else {
          if (demora > 45 && demora <= 90) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa3Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa2Amarillo;
                } else {
                  tarifa = tarifa3Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa3Amarillo;
              } else {
                tarifa = tarifa2Amarillo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Amarillo;
                } else {
                  tarifa = tarifa2Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                tarifa = tarifa1Amarillo;
              }
            }
          }
        }
      }
    }

    let tarifa1Rojo = 8297.28;
    let tarifa2Rojo = 10786.46;
    let tarifa3Rojo = 14022.4;
    let tarifa4Rojo = 11616.19;
    let tarifa5Rojo = 15101.05;
    let tarifa6Rojo = 19631.36;
    let tarifa7Rojo = 9956.74;
    let tarifa8Rojo = 12943.76;
    let tarifa9Rojo = 16826.89;

    if (color === "ROJO") {
      if (demora > 60) {
        if (demora > 60 && demora <= 90) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa3Amarillo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa2Amarillo;
              } else {
                tarifa = tarifa3Amarillo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa3Amarillo;
            } else {
              tarifa = tarifa2Amarillo;
            }
          }
        } else {
          if (demora > 90 && demora <= 180) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Amarillo;
                } else {
                  tarifa = tarifa2Amarillo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Amarillo;
              } else {
                tarifa = tarifa1Amarillo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Verde;
                } else {
                  tarifa = tarifa2Verde;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Verde;
              } else {
                tarifa = tarifa1Verde;
              }
            }
          }
        }
      } else {
        if (demora <= 15) {
          if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
            if (nocturno) {
              tarifa = tarifa5Rojo;
            } else {
              if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                tarifa = tarifa4Rojo;
              } else {
                tarifa = tarifa5Rojo;
              }
            }
          } else {
            if (nocturno) {
              tarifa = tarifa5Rojo;
            } else {
              tarifa = tarifa4Rojo;
            }
          }
        } else {
          if (demora > 15 && demora <= 30) {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa8Rojo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa7Rojo;
                } else {
                  tarifa = tarifa8Rojo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa8Rojo;
              } else {
                tarifa = tarifa7Rojo;
              }
            }
          } else {
            if (diaSemana === 6 || diaSemana === 0 || index !== -1) {
              if (nocturno) {
                tarifa = tarifa2Rojo;
              } else {
                if (hora >= 7 && hora < 12 && diaSemana === 6 && index === -1) {
                  tarifa = tarifa1Rojo;
                } else {
                  tarifa = tarifa2Rojo;
                }
              }
            } else {
              if (nocturno) {
                tarifa = tarifa2Rojo;
              } else {
                tarifa = tarifa1Rojo;
              }
            }
          }
        }
      }
    }

    return tarifa;
  }

  const obtenerServiciosFinalizadosPrestadores = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosfinalizadosprestadores",
        datos
      );
      let data = respuesta.data;

      //console.log(data)

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          colorInicio: data[i].colorInicio,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          detalleDiagnostico: data[i].detalleDiagnostico
            ? data[i].detalleDiagnostico
            : null,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
          derivado: data[i].moviles !== null ? data[i].moviles.nombre : null,
          tripulacionId:
            data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
          movilId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.movilId
              : data[i].derivadoId !== null
              ? data[i].derivadoId
              : null,
          tomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          pasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          salida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          llegada: data[i].fllegada ? formatDateTime(data[i].fllegada) : null,
          fin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          anulado: data[i].fanulado ? formatDateTime(data[i].fanulado) : null,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined
              ? Number(data[i].coseguroAbonar)
              : 0,
          usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
          usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
          usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
          usuarioLlegada: data[i].usuarioLlegada
            ? data[i].usuarioLlegada
            : null,
          usuarioFin: data[i].usuarioFin ? data[i].usuarioFin : null,
          usuarioAnulado: data[i].usuarioAnulado
            ? data[i].usuarioAnulado
            : null,
          coseguroAbonado:
            data[i].coseguroAbonado !== undefined
              ? Number(data[i].coseguroAbonado)
              : 0,

          //Tripulacion

          medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          telefonoTripulacion:
            data[i].tripulaciones && data[i].tripulaciones.telefonos
              ? data[i].tripulaciones.telefonos.nombre
              : null,
          // Laborales
          laborales: data[i].laborales !== null ? data[i].laborales : null,
          // Traslados
          trasladoServicio:
            data[i].trasladoServicio !== null ? data[i].trasladoServicio : null,
          // Eventos
          eventos: data[i].eventos !== null ? data[i].eventos : null,
          //H.C.
          hcServicio: data[i].hcServicio ? data[i].hcServicio : null,
          hcAdjunto: data[i].hcAdjunto ? data[i].hcAdjunto : null,
          //Colores
          colores: data[i].colores !== null ? data[i].colores : null,
        });
      }
      guardarServiciosFinalizados(local);

      //console.log(local);

      const localExcel = [];

      for (var i = 0; i < data.length; i++) {
        localExcel.push({
          Id: data[i].id,
          Indicativo: data[i].indicativo,
          IndicativoId: data[i].indicativo + " - " + data[i].id,
          //id: data[i].id,
          //Indicativo: data[i].id,
          FechaTomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          FechaPasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          FechaSalida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          FechaLlegada: data[i].fllegada
            ? formatDateTime(data[i].fllegada)
            : null,
          FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          IDTitular: data[i].titularId,
          NombreTitular: data[i].nombreTitular,
          IDAfiliado: data[i].afiliadoId,
          NombreAfiliado: data[i].nombre,
          Domicilio: data[i].domicilio,
          Localidad: data[i].localidad,
          Telefono: data[i].telefono,
          Documento: data[i].numeroDocumento,
          Edad: data[i].edad,
          Sexo: data[i].sexo,
          Grupo: data[i].grupos.nombre,
          Plan: data[i].planes.nombre,
          Antecedente: data[i].antecedentes.nombre,
          Tratamiento: data[i].tratamientos.nombre,
          MotivoConsulta: data[i].motivosConsultas.nombre,
          Diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          DetalleDiagnostico: data[i].detalleDiagnostico,
          ColorInicial: data[i].colorInicio,
          ColorFinal: data[i].color,
          Movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
          DetalleDerivado:
            data[i].moviles !== null ? data[i].moviles.nombre : null,
          Base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          Medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          Chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          Enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          CoseguroAbonar: Number(data[i].coseguroAbonar),
          CoseguroAbonado: Number(data[i].coseguroAbonado),
          ObraSocialConvenio: data[i].obraSocial,
          NroObraSocial: data[i].nroObraSocial,
          Covid: data[i].covid ? "SI" : "NO",
          ValorServicio: data[i].valorizacion,
          IndicativoEmisor: data[i].indicativoEmisor,
          Traslado: data[i].trasladoServicio ? "SI" : "NO",
          FechaTrabaja:
            data[i].laborales && data[i].laborales.trabaja
              ? data[i].laborales.fechaTrabaja
              : null,
          FechaCitado:
            data[i].laborales && data[i].laborales.citado
              ? data[i].laborales.fechaCitado
              : null,
          DemoraSalida: data[i].demoraSalida ? data[i].demoraSalida : 0,
          DemoraLlegada: data[i].demoraLlegada ? data[i].demoraLlegada : 0,
          DemoraToleranciaSalida:
            data[i].colores && data[i].colores.demoraSalida
              ? data[i].colores.demoraSalida
              : 0,
          DemoraToleranciaLlegada:
            data[i].colores && data[i].colores.demoraLlegada
              ? data[i].colores.demoraLlegada
              : 0,
          Empresa:
            data[i].afiliados &&
            data[i].afiliados.empresas &&
            data[i].afiliados.empresas.nombre
              ? data[i].afiliados.empresas.nombre
              : "",
          Cuota:
            data[i].afiliados && data[i].afiliados.cuota
              ? data[i].afiliados.cuota
              : 0,
        });
      }

      toast.current.clear();

      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);

      if (error.response && error.response.data && error.response.data.msg.substring(0,10) === "El período") {
        toast.current.clear();
        toast.current.show({
          severity: "error",
          summary: "Información",
          detail: error.response.data.msg,
          sticky: 3000,
        });
        setTimeout(() => {
          toast.current.clear();
        }, 3000);
  
      } 

    }
  };

  const obtenerServiciosAnulados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosanulados",
        datos
      );

      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          indicativo: data[i].id,
          //indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          colorInicio: data[i].colorInicio,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          detalleDiagnostico: data[i].detalleDiagnostico
            ? data[i].detalleDiagnostico
            : null,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          nroObraSocial: data[i].nroObraSocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoId: data[i].derivadoId !== null ? data[i].derivadoId : null,
          tripulacionId:
            data[i].tripulaciones !== null ? data[i].tripulaciones.id : null,
          movilId:
            data[i].tripulaciones !== null
              ? data[i].tripulaciones.movilId
              : data[i].derivadoId !== null
              ? data[i].derivadoId
              : null,
          tomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          pasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          salida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          llegada: data[i].fllegada ? formatDateTime(data[i].fllegada) : null,
          fin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          anulado: data[i].fanulado ? formatDateTime(data[i].fanulado) : null,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined
              ? Number(data[i].coseguroAbonar)
              : 0,
          usuarioTomado: data[i].usuarioTomado ? data[i].usuarioTomado : null,
          usuarioPasado: data[i].usuarioPasado ? data[i].usuarioPasado : null,
          usuarioSalida: data[i].usuarioSalida ? data[i].usuarioSalida : null,
          usuarioLlegada: data[i].usuarioLlegada
            ? data[i].usuarioLlegada
            : null,

          usuarioFin: data[i].usuarioFin ? data[i].usuarioFin : null,
          usuarioAnulado: data[i].usuarioAnulado
            ? data[i].usuarioAnulado
            : null,
          coseguroAbonado:
            data[i].coseguroAbonado !== undefined
              ? Number(data[i].coseguroAbonado)
              : 0,

          //Tripulacion

          medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          telefonoTripulacion:
            data[i].tripulaciones && data[i].tripulaciones.telefonos
              ? data[i].tripulaciones.telefonos.nombre
              : null,
          // Laborales
          laborales: data[i].laborales !== null ? data[i].laborales : null,
          // Traslados
          trasladoServicio:
            data[i].trasladoServicio !== null ? data[i].trasladoServicio : null,
          // Eventos
          eventos: data[i].eventos !== null ? data[i].eventos : null,
          //H.C.
          hcServicio: data[i].hcServicio ? data[i].hcServicio : null,
          hcAdjunto: data[i].hcAdjunto ? data[i].hcAdjunto : null,
          //Colores
          colores: data[i].colores !== null ? data[i].colores : null,
        });
      }
      guardarServiciosAnulados(local);

      //console.log(local);

      const localExcel = [];

      for (var i = 0; i < data.length; i++) {
        localExcel.push({
          //id: data[i].id,
          Indicativo: data[i].id,
          FechaTomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          FechaPasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          FechaSalida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          FechaLlegada: data[i].fllegada
            ? formatDateTime(data[i].fllegada)
            : null,
          FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          FechaAnulado: data[i].fanulado
            ? formatDateTime(data[i].fanulado)
            : null,
          UsuarioAnulado: data[i].usuarioAnulado,
          IDTitular: data[i].titularId,
          NombreTitular: data[i].nombreTitular,
          IDAfiliado: data[i].afiliadoId,
          NombreAfiliado: data[i].nombre,
          Domicilio: data[i].domicilio,
          Telefono: data[i].telefono,
          Documento: data[i].numeroDocumento,
          Edad: data[i].edad,
          Sexo: data[i].sexo,
          Plan: data[i].planes.nombre,
          Antecedente: data[i].antecedentes.nombre,
          Tratamiento: data[i].tratamientos.nombre,
          MotivoConsulta: data[i].motivosConsultas.nombre,
          Diagnostico:
            data[i].diagnosticos !== null ? data[i].diagnosticos.nombre : null,
          DetalleDiagnostico: data[i].detalleDiagnostico,
          ColorInicial: data[i].colorInicio,
          ColorFinal: data[i].color,
          Movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
          Base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          Medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          Chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          Enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          CoseguroAbonar: data[i].coseguroAbonar,
          CoseguroAbonado: data[i].coseguroAbonado,
          ObraSocialConvenio: data[i].obraSocial,
          NroObraSocial: data[i].nroObraSocial,
          Covid: data[i].covid ? "SI" : "NO",
          ValorServicio: data[i].valorizacion,
          Traslado: data[i].trasladoServicio ? "SI" : "NO",
          FechaTrabaja:
            data[i].laborales && data[i].laborales.trabaja
              ? data[i].laborales.fechaTrabaja
              : null,
          FechaCitado:
            data[i].laborales && data[i].laborales.citado
              ? data[i].laborales.fechaCitado
              : null,
          DemoraSalida: data[i].demoraSalida ? data[i].demoraSalida : 0,
          DemoraLlegada: data[i].demoraLlegada ? data[i].demoraLlegada : 0,
          DemoraToleranciaSalida:
            data[i].colores && data[i].colores.demoraSalida
              ? data[i].colores.demoraSalida
              : 0,
          DemoraToleranciaLlegada:
            data[i].colores && data[i].colores.demoraLlegada
              ? data[i].colores.demoraLlegada
              : 0,
        });
      }

      setServiciosAnuladosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerServiciosAnual = async (datos) => {
    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerserviciosanual",
        datos
      );
      let data = respuesta.data;

      //console.log(data)

      const localExcel = [];
      localExcel.push({
        mes: "ENERO",
        rojo:
          data.amount1.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount1.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount1.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount1.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount1.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount1.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount1.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount1.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount1.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount1.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount1.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount1.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount1.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount1.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount1.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount1.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "FEBRERO",
        rojo:
          data.amount2.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount2.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount2.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount2.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount2.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount2.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount2.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount2.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount2.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount2.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount2.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount2.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount2.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount2.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount2.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount2.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "MARZO",
        rojo:
          data.amount3.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount3.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount3.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount3.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount3.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount3.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount3.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount3.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount3.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount3.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount3.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount3.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount3.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount3.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount3.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount3.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "ABRIL",
        rojo:
          data.amount4.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount4.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount4.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount4.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount4.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount4.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount4.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount4.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount4.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount4.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount4.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount4.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount4.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount4.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount4.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount4.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "MAYO",
        rojo:
          data.amount5.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount5.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount5.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount5.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount5.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount5.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount5.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount5.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount5.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount5.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount5.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount5.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount5.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount5.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount5.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount5.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "JUNIO",
        rojo:
          data.amount6.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount6.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount6.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount6.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount6.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount6.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount6.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount6.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount6.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount6.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount6.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount6.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount6.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount6.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount6.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount6.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "JULIO",
        rojo:
          data.amount7.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount7.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount7.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount7.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount7.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount7.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount7.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount7.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount7.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount7.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount7.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount7.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount7.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount7.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount7.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount7.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "AGOSTO",
        rojo:
          data.amount8.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount8.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount8.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount8.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount8.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount8.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount8.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount8.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount8.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount8.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount8.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount8.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount8.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount8.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount8.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount8.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "SEPTIEMBRE",
        rojo:
          data.amount9.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount9.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount9.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount9.find((element) => element.color === "AMARILLO").count
            : 0,
        verde:
          data.amount9.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount9.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount9.findIndex((element) => element.color === "FUCCIA") !== -1
            ? data.amount9.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount9.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount9.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount9.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount9.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount9.findIndex((element) => element.color === "BLANCO") !== -1
            ? data.amount9.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount9.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount9.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "OCTUBRE",
        rojo:
          data.amount10.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount10.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount10.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount10.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount10.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount10.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount10.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount10.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount10.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount10.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount10.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount10.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount10.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount10.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount10.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount10.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "NOVIEMBRE",
        rojo:
          data.amount11.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount11.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount11.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount11.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount11.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount11.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount11.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount11.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount11.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount11.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount11.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount11.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount11.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount11.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount11.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount11.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });
      localExcel.push({
        mes: "DICIEMBRE",
        rojo:
          data.amount12.findIndex((element) => element.color === "ROJO") !== -1
            ? data.amount12.find((element) => element.color === "ROJO").count
            : 0,
        amarillo:
          data.amount12.findIndex((element) => element.color === "AMARILLO") !==
          -1
            ? data.amount12.find((element) => element.color === "AMARILLO")
                .count
            : 0,
        verde:
          data.amount12.findIndex((element) => element.color === "VERDE") !== -1
            ? data.amount12.find((element) => element.color === "VERDE").count
            : 0,
        fuccia:
          data.amount12.findIndex((element) => element.color === "FUCCIA") !==
          -1
            ? data.amount12.find((element) => element.color === "FUCCIA").count
            : 0,
        azul:
          data.amount12.findIndex((element) => element.color === "AZUL") !== -1
            ? data.amount12.find((element) => element.color === "AZUL").count
            : 0,
        celeste:
          data.amount12.findIndex((element) => element.color === "CELESTE") !==
          -1
            ? data.amount12.find((element) => element.color === "CELESTE").count
            : 0,
        blanco:
          data.amount12.findIndex((element) => element.color === "BLANCO") !==
          -1
            ? data.amount12.find((element) => element.color === "BLANCO").count
            : 0,
        naranja:
          data.amount12.findIndex((element) => element.color === "NARANJA") !==
          -1
            ? data.amount12.find((element) => element.color === "NARANJA").count
            : 0,
        total: 0,
        porcentaje: 0,
      });

      toast.current.clear();

      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const modificarTripulacionCerrada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificartripulacioncerrada",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerHorariosTripulacion = async (datos) => {
    const { tripulanteSeleccionado, tripulante, horario } = datos;

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerhorariostripulacion",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      let data2 = null;
      if (horario === "tripulacion") {
        data2 = respuesta.data.responseServicios;
      }

      function group(arr, key) {
        return [
          ...arr
            .reduce(
              (acc, o) => acc.set(o[key], (acc.get(o[key]) || []).concat(o)),
              new Map()
            )
            .values(),
        ];
      }

      const local = [];

      for (var i = 0; i < data.length; i++) {
        if (
          data[i].medicos !== null ||
          data[i].choferes !== null ||
          data[i].enfermeros !== null
        ) {
          local.push({
            ID: data[i].id,
            ...(horario === "tripulacion"
              ? {
                  Tripulante: tripulante,
                }
              : {}),
            Base: data[i].bases !== null ? data[i].bases.nombre : null,
            Movil: data[i].movilId,
            NombreMedico:
              data[i].medicos !== null ? data[i].medicos.nombre : null,
            FechaIngresoMedico: data[i].medicoIngreso
              ? formatDateTime(data[i].medicoIngreso)
              : null,
            FechaSalidaMedico: data[i].medicoSalida
              ? formatDateTime(data[i].medicoSalida)
              : null,

            NombreChofer:
              data[i].choferes !== null ? data[i].choferes.nombre : null,
            FechaIngresoChofer: data[i].choferIngreso
              ? formatDateTime(data[i].choferIngreso)
              : null,
            FechaSalidaChofer: data[i].choferSalida
              ? formatDateTime(data[i].choferSalida)
              : null,

            NombreEnfermero:
              data[i].enfermeros !== null ? data[i].enfermeros.nombre : null,
            FechaIngresoEnfermero: data[i].enfermeroIngreso
              ? formatDateTime(data[i].enfermeroIngreso)
              : null,
            FechaSalidaEnfermero: data[i].enfermeroSalida
              ? formatDateTime(data[i].enfermeroSalida)
              : null,
            ...(horario === "tripulacion"
              ? {
                  CantidadServicios: data2.filter(
                    (obj) => obj.tripulacionId === data[i].id
                  ).length,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  FechaArmado: data[i].fechaArmado
                    ? formatDateTime(data[i].fechaArmado)
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  FechaCerrado: data[i].fechaCerrado
                    ? formatDateTime(data[i].fechaCerrado)
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  UsuarioArmado: data[i].usuarioArmado
                    ? data[i].usuarioArmado
                    : null,
                }
              : {}),

            ...(horario === "armado"
              ? {
                  UsuarioCerrado: data[i].usuarioActualizado
                    ? data[i].usuarioActualizado
                    : null,
                }
              : {}),

            /*
          Indicativo: data[i].indicativo,
          FechaTomado: data[i].ftomado ? formatDateTime(data[i].ftomado) : null,
          FechaPasado: data[i].fpasado ? formatDateTime(data[i].fpasado) : null,
          FechaSalida: data[i].fsalida ? formatDateTime(data[i].fsalida) : null,
          FechaLlegada: data[i].fllegada
            ? formatDateTime(data[i].fllegada)
            : null,
          FechaFin: data[i].ffin ? formatDateTime(data[i].ffin) : null,
          IDTitular: data[i].titularId,
          NombreTitular: data[i].nombreTitular,
          IDAfiliado: data[i].afiliadoId,
          NombreAfiliado: data[i].nombre,
          Domicilio: data[i].domicilio,
          Telefono: data[i].telefono,
          Documento: data[i].numeroDocumento,
          Edad: data[i].edad,
          Sexo: data[i].sexo,
          Movil:
            data[i].tripulaciones && data[i].tripulaciones.moviles
              ? data[i].tripulaciones.moviles.nombre
              : null,
          Derivado: data[i].derivadoId !== null ? data[i].derivadoId : null,
          IDTripulacion: data[i].tripulaciones
            ? data[i].tripulaciones.id
            : null,
          Base:
            data[i].tripulaciones && data[i].tripulaciones.bases
              ? data[i].tripulaciones.bases.nombre
              : null,
          Medico:
            data[i].tripulaciones && data[i].tripulaciones.medicos
              ? data[i].tripulaciones.medicos.nombre
              : null,
          Chofer:
            data[i].tripulaciones && data[i].tripulaciones.choferes
              ? data[i].tripulaciones.choferes.nombre
              : null,
          Enfermero:
            data[i].tripulaciones && data[i].tripulaciones.enfermeros
              ? data[i].tripulaciones.enfermeros.nombre
              : null,
          CoseguroAbonar: data[i].coseguroAbonar,
          CoseguroAbonado: data[i].coseguroAbonado,
          obraSocialConvenio: data[i].obraSocial,
          covid: data[i].covid ? "SI" : "NO",
          valorServicio: data[i].valorizacion,
          */
          });
        }
      }
      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;

      toast.current.clear();
      setServiciosFinalizadosExcel(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerLiquidacionesMedicas = async (datos) => {
    const { tope, edadPediatrico, nocturnoDesde } = datos;

    try {
      toast.current.show({
        severity: "info",
        summary: "Información",
        detail: "Espere un momento...",
        sticky: true,
      });

      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenerliquidacionesmedicas",
        datos
      );
      let data = respuesta.data.responseTripulacion;
      let data2 = respuesta.data.responseServicios;
      let data3 = respuesta.data.responseMedico;
      let feriados = respuesta.data.responseFeriados;

      const local = [];

      function totalCoseguros(tripulacionId) {
        let totalCoseguro = 0;
        for (var i = 0; i < data2.length; i++) {
          if (data2[i].tripulacionId === tripulacionId) {
            totalCoseguro = totalCoseguro + Number(data2[i].coseguroAbonado);
          }
        }
        return totalCoseguro;
      }

      function totalPediatricos(tripulacionId) {
        let totalPediatrico = 0;
        for (var i = 0; i < data2.length; i++) {
          if (
            data2[i].tripulacionId === tripulacionId &&
            data2[i].edad <= edadPediatrico
          ) {
            totalPediatrico = totalPediatrico + 1;
          }
        }
        return totalPediatrico;
      }

      for (var i = 0; i < data.length; i++) {
        var allDate = data[i].medicoIngreso.replace("T", " ");
        allDate = allDate.replace(".", " ");
        allDate = allDate.split(" ");
        var thisDate = allDate[0].split("-");
        var thisTime = allDate[1].split(":");
        var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
        var newTime = [thisTime[0], thisTime[1]].join(":");

        let dArr = newDate.split("/");
        let semana = dArr[2] + "/" + dArr[1] + "/" + dArr[0];

        let diaSemana = new Date(semana).getDay();

        const index = feriados.findIndex(
          (feriado) => feriado.fecha === newDate
        );
        let time1 = newTime.split(":");
        let hora = time1[0];
        const nocturno = newTime >= nocturnoDesde;

        local.push({
          ID: data[i].id,
          Base: data[i].bases !== null ? data[i].bases.nombre : null,
          Movil: data[i].movilId,
          NombreMedico:
            data[i].medicos !== null ? data[i].medicos.nombre : null,
          FechaIngresoMedico: data[i].medicoIngreso
            ? formatDateTime(data[i].medicoIngreso)
            : null,
          FechaSalidaMedico: data[i].medicoSalida
            ? formatDateTime(data[i].medicoSalida)
            : null,
          CantidadServicios: data2.filter(
            (obj) => obj.tripulacionId === data[i].id
          ).length,
          Pediatricos: totalPediatricos(data[i].id),
          Coseguros: parseFloat(totalCoseguros(data[i].id)).toFixed(2),
          /*
          ValorGuardia: data3.valorGuardia
            ? parseFloat(data3.valorGuardia).toFixed(2)
            : parseFloat(0).toFixed(2),
           */
          ValorGuardia:
            data2.filter((obj) => obj.tripulacionId === data[i].id).length >=
            tope
              ? data3.valorGuardia
                ? parseFloat(data3.valorGuardia).toFixed(2)
                : 0
              : data3.valorGuardia
              ? parseFloat(
                  data3.valorGuardia - totalCoseguros(data[i].id)
                ).toFixed(2)
              : 0,
          Presentismo: data3.presentismo ? data3.presentismo : 0,
          AdicionalPediatrico: data3.pediatrico
            ? parseFloat(
                data3.pediatrico * totalPediatricos(data[i].id)
              ).toFixed(2)
            : parseFloat(0).toFixed(2),
          AdicionalNocturno:
            nocturno && data3.nocturno
              ? parseFloat((data3.valorGuardia * data3.nocturno) / 100).toFixed(
                  2
                )
              : parseFloat(0).toFixed(2),
          AdicionalSadofe:
            (index !== -1 || diaSemana === 6 || diaSemana === 0) && data3.sadofe
              ? parseFloat((data3.valorGuardia * data3.sadofe) / 100).toFixed(2)
              : parseFloat(0).toFixed(2),
          /*   
          Autoliquidable:
            data2.filter((obj) => obj.tripulacionId === data[i].id).length >=
            tope
              ? parseFloat(totalCoseguros(data[i].id)).toFixed(2)
              : parseFloat(0).toFixed(2),
            */
        });
      }
      //const result = group(local, "IDTripulacion");
      //const localExcel = [];

      //const count = array.filter((obj) => obj.id === id).length;
      let totalGuardia = 0;
      let totalCoseguro = 0;
      let totalPresentismo = 0;
      let totalPediatrico = 0;
      let totalNocturno = 0;
      let totalSadofe = 0;
      let localExcel = [];
      for (var i = 0; i < local.length; i++) {
        if (local[i].CantidadServicios > 0) {
          localExcel.push(local[i]);
          totalGuardia = totalGuardia + Number(local[i].ValorGuardia);
          totalCoseguro = totalCoseguro + Number(local[i].Coseguros);
          totalPresentismo = totalPresentismo + Number(local[i].Presentismo);
          totalPediatrico =
            totalPediatrico + Number(local[i].AdicionalPediatrico);
          totalNocturno = totalNocturno + Number(local[i].AdicionalNocturno);
          totalSadofe = totalSadofe + Number(local[i].AdicionalSadofe);
        }
      }

      localExcel.push({
        Base: "TOTAL",
        Coseguros: parseFloat(totalCoseguro).toFixed(2),
        ValorGuardia: parseFloat(totalGuardia).toFixed(2),
        Presentismo: parseFloat(totalPresentismo).toFixed(2),
        AdicionalPediatrico: parseFloat(totalPediatrico).toFixed(2),
        AdicionalNocturno: parseFloat(totalNocturno).toFixed(2),
        AdicionalSadofe: parseFloat(totalSadofe).toFixed(2),
      });

      toast.current.clear();
      setServiciosFinalizadosExcel(localExcel);
    } catch (error) {
      console.log(error.response);
    }
  };

  const cerrarServicioEmergencia = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cerrarservicioemergencia",
        datos
      );
      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
      guardarServicioSeleccionado(null);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Tripulaciones

  const obtenerTripulaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulaciones",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          movil: data[i].moviles.nombre,
          base: data[i].bases.nombre,
          baseId: data[i].bases.id,
          telefono:
            data[i].telefonos !== null ? data[i].telefonos.nombre : null,
          telefonoId: data[i].telefonos !== null ? data[i].telefonos.id : null,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          medicoId:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicoId
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          choferId:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferId
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          enfermeroId:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeroId
              : null,
          activo: data[i].activo,
          localizacion: data[i].localizacion,
          servicioId:
            data[i].servicioId !== null && data[i].servicioId !== 0
              ? data[i].servicioId
              : null,
          confirmaApp: data[i].confirmaApp ? data[i].confirmaApp : null,
          medicoEstado: data[i].medicoEstado,
          choferEstado: data[i].choferEstado,
          enfermeroEstado: data[i].enfermeroEstado,
          lat: data[i].lat,
          lon: data[i].lon,
          fechaGps: data[i].fechaGps,
          medicoIngreso:
            data[i].medicoIngreso !== null
              ? data[i].medicoIngreso.substring(0, 16)
              : "",
          choferIngreso:
            data[i].choferIngreso !== null
              ? data[i].choferIngreso.substring(0, 16)
              : "",
          enfermeroIngreso:
            data[i].enfermeroIngreso !== null
              ? data[i].enfermeroIngreso.substring(0, 16)
              : "",
          medicoSalida:
            data[i].medicoSalida !== null
              ? data[i].medicoSalida.substring(0, 16)
              : "",
          choferSalida:
            data[i].choferSalida !== null
              ? data[i].choferSalida.substring(0, 16)
              : "",
          enfermeroSalida:
            data[i].enfermeroSalida !== null
              ? data[i].enfermeroSalida.substring(0, 16)
              : "",
        });
      }

      guardarTripulaciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTripulacionesCerradas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacionescerradas",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movilId: data[i].movilId,
          movil: data[i].moviles.nombre,
          base: data[i].bases.nombre,
          baseId: data[i].bases.id,
          telefono:
            data[i].telefonos !== null ? data[i].telefonos.nombre : null,
          telefonoId: data[i].telefonos !== null ? data[i].telefonos.id : null,
          medico:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicos.nombre
              : null,
          medicoId:
            data[i].medicoId !== null && data[i].medicoId !== 0
              ? data[i].medicoId
              : null,
          chofer:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferes.nombre
              : null,
          choferId:
            data[i].choferId !== null && data[i].choferId !== 0
              ? data[i].choferId
              : null,
          enfermero:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeros.nombre
              : null,
          enfermeroId:
            data[i].enfermeroId !== null && data[i].enfermeroId !== 0
              ? data[i].enfermeroId
              : null,
          activo: data[i].activo,
          localizacion: data[i].localizacion,
          servicioId:
            data[i].servicioId !== null && data[i].servicioId !== 0
              ? data[i].servicioId
              : null,
          confirmaApp: data[i].confirmaApp ? data[i].confirmaApp : null,
          medicoEstado: data[i].medicoEstado,
          choferEstado: data[i].choferEstado,
          enfermeroEstado: data[i].enfermeroEstado,
          lat: data[i].lat,
          lon: data[i].lon,
          fechaGps: data[i].fechaGps,
          medicoIngreso:
            data[i].medicoIngreso !== null
              ? data[i].medicoIngreso.substring(0, 16)
              : "",
          choferIngreso:
            data[i].choferIngreso !== null
              ? data[i].choferIngreso.substring(0, 16)
              : "",
          enfermeroIngreso:
            data[i].enfermeroIngreso !== null
              ? data[i].enfermeroIngreso.substring(0, 16)
              : "",
          medicoSalida:
            data[i].medicoSalida !== null
              ? data[i].medicoSalida.substring(0, 16)
              : "",
          choferSalida:
            data[i].choferSalida !== null
              ? data[i].choferSalida.substring(0, 16)
              : "",
          enfermeroSalida:
            data[i].enfermeroSalida !== null
              ? data[i].enfermeroSalida.substring(0, 16)
              : "",
        });
      }

      guardarTripulacionesCerradas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTripulacionesLiberados = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulaciones",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        if (data[i].servicioId === null) {
          local.push({
            id: data[i].id,
            movil: data[i].movilId,
            base: data[i].bases.nombre,
            telefono:
              data[i].telefonos !== null ? data[i].telefonos.nombre : null,
            telefonoId:
              data[i].telefonos !== null ? data[i].telefonos.id : null,
            medico: data[i].medicoId !== 0 ? data[i].medicos.nombre : null,
            medicoId: data[i].medicoId !== 0 ? data[i].medicoId : null,
            chofer: data[i].choferId !== 0 ? data[i].choferes.nombre : null,
            choferId: data[i].choferId !== 0 ? data[i].choferId : null,
            enfermero:
              data[i].enfermeroId !== 0 ? data[i].enfermeros.nombre : null,
            enfermeroId: data[i].enfermeroId !== 0 ? data[i].enfermeroId : null,
            activo: data[i].activo,
            localizacion: data[i].localizacion,
            servicioId: data[i].servicioId,
          });
        }
      }

      guardarTripulaciones(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const agregarTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregartripulacion",
        datos
      );

      let data = respuesta.data;
      mensajeAlerta(data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertripulacion",
        datos
      );

      let data = respuesta.data;

      if (data.medicoIngreso !== null && data.medicoIngreso !== "") {
        data.medicoIngreso = data.medicoIngreso.substring(0, 16);
      }
      if (data.medicoSalida !== null && data.medicoSalida !== "") {
        data.medicoSalida = data.medicoSalida.substring(0, 16);
      }

      if (data.choferIngreso !== null && data.choferIngreso !== "") {
        data.choferIngreso = data.choferIngreso.substring(0, 16);
      }
      if (data.choferSalida !== null && data.choferSalida !== "") {
        data.choferSalida = data.choferSalida.substring(0, 16);
      }

      if (data.enfermeroIngreso !== null && data.enfermeroIngreso !== "") {
        data.enfermeroIngreso = data.enfermeroIngreso.substring(0, 16);
      }
      if (data.enfermeroSalida !== null && data.enfermeroSalida !== "") {
        data.enfermeroSalida = data.enfermeroSalida.substring(0, 16);
      }
      if (data.localizacion === null) {
        data.localizacion = false;
      }

      //guardarTripulacion(data);
      guardarTripulacionModificada(data);
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTripulacion = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizartripulacion",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const actualizarTripulacionCambiada = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/actualizartripulacioncambiada",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const enviarNotificaciones = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/enviarnotificaciones",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      //localStorage.setItem("refrescar", new Date());
      //setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const asignarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/asignarmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const asignarMovilDerivado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/asignarmovilderivado",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const salidaMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/salidamovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const llegadaMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/llegadamovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const anularServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/anularservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const modificarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificarservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const modificarServicioFinalizado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/modificarserviciofinalizado",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const activarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/activarservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const simultaneoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/simultaneoservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const elevarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/elevarservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const reclamarServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/reclamarservicio",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const confirmarMovilLiberado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/confirmarmovilliberado",
        datos
      );

      //mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const cambiarMotivoConsulta = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cambiarmotivoconsulta",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const liberarMovil = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/liberarmovil",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  // Moviles Derivados

  const obtenerMovilesDerivados = async () => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenermovilesderivados"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          movil: data[i].movilId,
          nombre: data[i].nombre,
          detalle: data[i].detalle,
          lat: data[i].lat,
          lon: data[i].lon,
          derivadoServicioId: data[i].derivadoServicioId,
        });
      }

      //console.log(local)
      guardarMovilesDerivados(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  // Traslados

  function convertUTCDateToLocalDate(date) {
    return new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );
  }

  function formatDateTime(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  function formatDate(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    return newDate;
  }

  const obtenerTraslados = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/servicios/obtenertraslados"
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          llegada: formatDateTime(data[i].llegada),
          afiliadoId: data[i].afiliadoId,
          afiliadoNombre: data[i].nombre,
          afiliadoTitular: data[i].nombreTitular,
          domicilioOrigen: data[i].domicilio,
          //Traslados
          domicilioDestino: data[i].trasladosProgramados.destino,
          tipoMovil: data[i].trasladosProgramados.tiposMoviles.nombre,
          espera: data[i].trasladosProgramados.espera,
          retorno: data[i].trasladosProgramados.retorno,
          ftomadoAzul: formatDateTime(data[i].trasladosProgramados.ftomado),
          usuarioTomadoAzul: data[i].trasladosProgramados.usuarioTomado,
          trasladoId: data[i].trasladosProgramados.id,
          //
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obraSocial: data[i].obraSocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,
        });
      }

      guardarTraslados(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertrasladoservicio",
        datos
      );
      let data = respuesta.data;

      guardarTrasladoServicio(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladosServicios = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenertrasladosservicios",
        datos
      );
      let data = respuesta.data;

      guardarTrasladosServicios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerTrasladosServiciosPendientes = async () => {
    try {
      const respuesta = await clienteAxios.get(
        "/api/servicios/obtenertrasladosserviciospendientes"
      );
      let data = respuesta.data;

      guardarTrasladosServicios(data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarTrasladoProgramado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminartrasladoprogramado",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const iniciarTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/iniciartrasladoservicio",
        datos
      );
      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const finalizarTrasladoServicio = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/finalizartrasladoservicio",
        datos
      );
      mensajeAlerta(respuesta.data, "success");
      localStorage.setItem("refrescar", new Date());
      setRefrescar(new Date());
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  //Eventos

  const obtenerEventos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/servicios/obtenereventos");
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          llegada: formatDateTime(data[i].llegada),
          afiliadoId: data[i].afiliadoId,
          afiliadoNombre: data[i].nombre,
          afiliadoTitular: data[i].nombreTitular,
          domicilioOrigen: data[i].domicilio,
          //Eventos
          duracion: data[i].eventosProgramados.duracion,
          cantidadMoviles: data[i].eventosProgramados.cantidadMoviles,
          dotacion: data[i].eventosProgramados.dotacion,
          tipoEvento: data[i].eventosProgramados.tipoEvento,
          responsable: data[i].eventosProgramados.responsable,
          contacto: data[i].eventosProgramados.contacto,
          pedido: formatDate(data[i].eventosProgramados.pedido),
          telefono: data[i].eventosProgramados.telefono,
          cantidadPersonas: data[i].eventosProgramados.cantidadPersonas,
          descripcion: data[i].eventosProgramados.descripcion,
          ftomadoNaranja: formatDateTime(data[i].eventosProgramados.ftomado),
          usuarioTomadoNaranja: data[i].eventosProgramados.usuarioTomado,
          eventoId: data[i].eventosProgramados.id,
          //
          indicativo: data[i].indicativo,
          indicativoId: data[i].indicativo + " - " + data[i].id,
          color: data[i].color,
          grupo: data[i].grupos.nombre,
          domicilio:
            data[i].entreCalles === ""
              ? data[i].domicilio
              : data[i].domicilio + " entre " + data[i].entreCalles,
          telefono: data[i].telefono,
          nombre: data[i].nombre,
          estado: data[i].estado,
          titularId: data[i].titularId,
          afiliadoId: data[i].afiliadoId,
          plan: data[i].planes.nombre,
          nombreTitular: data[i].nombreTitular,
          numeroDocumento: data[i].numeroDocumento,
          edad: data[i].edad,
          sexo: data[i].sexo,
          antecedente: data[i].antecedentes.nombre,
          tratamiento: data[i].tratamientos.nombre,
          motivoConsulta: data[i].motivosConsultas.nombre,
          operadorEmisor: data[i].operadorEmisor,
          indicativoEmisor: data[i].indicativoEmisor,
          obrasocial: data[i].obrasocial,
          covid: data[i].covid,
          lat: data[i].lat,
          lon: data[i].lon,
          coseguroAbonar:
            data[i].coseguroAbonar !== undefined ? data[i].coseguroAbonar : 0,
        });
      }

      guardarEventos(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const eliminarEventoProgramado = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminareventoprogramado",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarNota = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnota",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const agregarNotaPendiente = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/agregarnotapendiente",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const obtenerNotas = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotas",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nota: data[i].nota,
          fecha: formatDateTime(data[i].fecha),
          usuario: data[i].usuario,
        });
      }

      setNotas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const obtenerNotasPendientes = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/obtenernotaspendientes",
        datos
      );
      let data = respuesta.data;

      const local = [];

      for (var i = 0; i < data.length; i++) {
        local.push({
          id: data[i].id,
          nota: data[i].nota,
          fecha: formatDateTime(data[i].fecha),
          usuario: data[i].usuario,
        });
      }

      setNotas(local);
    } catch (error) {
      console.log(error.response);
    }
  };

  const cancelarNotificationApp = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/cancelarnotificacionapp",
        datos
      );

      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };

  const uploadFiles = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/uploadfiles",
        datos
      );
      mensajeAlerta(respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };


  const uploadFilesPrestadores = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/uploadfilesprestadores",
        datos
      );
      mensajeAlerta(respuesta.data.msg, "success");
    } catch (error) {
      console.log(error.response);
      mensajeAlerta(error.response.data, "error");
    }
  };


  const eliminarHistoriaClinica = async (datos) => {
    try {
      const respuesta = await clienteAxios.post(
        "/api/servicios/eliminarhistoriaclinica",
        datos
      );
      mensajeAlerta(respuesta.data, "success");
    } catch (error) {
      console.log(error.response);
    }
  };



  return (
    <Fragment>
      <Toast ref={toast} />
      <Provider
        value={{
          numeroTelefono,
          valBusqueda,
          rows,
          afiliado,
          titular,
          selectedRow,
          numeroIndicativo,
          despachos,
          resumen,
          estadoTomado,
          servicio,
          servicioModificado,
          tripulaciones,
          tripulacionesCerradas,
          tripulacion,
          tripulacionModo,
          drawer,
          openDrawer,
          servicioSeleccionado,
          cierreEmergencias,
          cierreMedicamentos,
          cierreFuccia,
          chipData,
          tomaAzul,
          tomaNaranja,
          openModalAzul,
          openModalFuccia,
          openModalNaranja,
          traslados,
          eventos,
          notas,
          nota,
          notaVisible,
          refrescar,
          movilMarcado,
          movilesDerivados,
          serviciosFinalizados,
          serviciosAnulados,
          trasladosServicios,
          trasladoServicio,
          filtrosListadosServicios,
          serviciosFinalizadosExcel,
          serviciosAnuladosExcel,
          valorizacion,
          cantidadServicios,
          demorados,
          tripulacionModificada,
          guardarValBusqueda,
          capturarTelefonoPaciente,
          obtenerDatosPacientes,
          obtenerDatosTitulares,
          guardarAfiliado,
          guardarTitular,
          buscarTitular,
          buscarValorizacion,
          setSelectedRow,
          agregarNuevoServicio,
          agregarNuevoServicioPendiente,
          setRows,
          guardarValBusqueda,
          obtenerServiciosPendientes,
          obtenerServicio,
          setEstadoTomado,
          guardarServicio,
          guardarServicioModificado,
          agregarTripulacion,
          guardarTripulaciones,
          guardarTripulacionesCerradas,
          guardarTripulacion,
          obtenerTripulacion,
          obtenerTripulaciones,
          obtenerTripulacionesCerradas,
          guardarDemorados,
          guardarTripulacionModo,
          actualizarTripulacion,
          actualizarTripulacionCambiada,
          obtenerTripulacionesLiberados,
          setDrawer,
          setOpenDrawer,
          guardarServicioSeleccionado,
          asignarMovil,
          asignarMovilDerivado,
          salidaMovil,
          llegadaMovil,
          anularServicio,
          modificarServicio,
          activarServicio,
          simultaneoServicio,
          guardarCierreEmergencias,
          guardarCierreMedicamentos,
          guardarCierreFuccia,
          setChipData,
          guardarTomaAzul,
          guardarTomaNaranja,
          setOpenModalAzul,
          setOpenModalFuccia,
          setOpenModalNaranja,
          guardarTraslados,
          obtenerTraslados,
          obtenerEventos,
          eliminarTrasladoProgramado,
          eliminarEventoProgramado,
          agregarNota,
          agregarNotaPendiente,
          obtenerNotas,
          obtenerNotasPendientes,
          setNota,
          setNotaVisible,
          setRefrescar,
          liberarMovil,
          setMovilMarcado,
          cerrarServicioEmergencia,
          obtenerMovilesDerivados,
          guardarMovilesDerivados,
          enviarNotificaciones,
          cancelarNotificationApp,
          guardarServiciosFinalizados,
          guardarServiciosAnulados,
          obtenerServiciosFinalizadosPrestadores,
          obtenerServiciosAnulados,
          guardarTrasladosServicios,
          iniciarTrasladoServicio,
          finalizarTrasladoServicio,
          obtenerTrasladoServicio,
          obtenerTrasladosServicios,
          uploadFiles,
          uploadFilesPrestadores,
          guardarFiltrosListadosServicios,
          obtenerTrasladosServiciosPendientes,
          obtenerDatosConvenios,
          guardarValorizacion,
          elevarServicio,
          reclamarServicio,
          cambiarMotivoConsulta,
          confirmarMovilLiberado,
          obtenerCantidadServiciosTitular,
          obtenerHorariosTripulacion,
          obtenerServicioFinalizado,
          modificarServicioFinalizado,
          verificarEstadoMoviles,
          guardarTripulacionModificada,
          modificarTripulacionCerrada,
          obtenerServiciosAnual,
          obtenerLiquidacionesMedicas,
          eliminarHistoriaClinica,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { ServiciosProvider, Consumer as ServiciosConsumer, ServiciosContext };
