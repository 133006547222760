import React, { useState, useContext, Fragment } from "react";
import clienteAxios from "../config/axios";
import tokenAuth from "../config/token";
import { createContext } from "react";
import { AlertContext } from "./AlertContext";
import { responsiveFontSizes } from "@material-ui/core";

const AuthContext = createContext();
const { Provider, Consumer } = AuthContext;

function AuthProvider({ children }) {
  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [state, guardarState] = useState({
    autenticado: false,
    usuario: null,
    cargando: true,
    token: null,
    nombre: null,
    id: null,
    dias: 0
});


  // Retorna el usuario autenticado
  const prestadorAutenticado = async () => {
    const token = localStorage.getItem("token");
    tokenAuth(token);
    try {

      const respuesta = await clienteAxios.post("/api/auth/prestadorautenticado");
      if (token === null) {
        guardarState({
          ...state,
          autenticado: false,
          usuario: null,
          cargando: false,
          token: null,
          nombre: null,
          id: null,
          dias: 0
        });
        return;
      }

      guardarState({
        ...state,
        autenticado: true,
        usuario: respuesta.data.usuario.usuario,
        cargando: false,
        nombre: respuesta.data.usuario.usuario,
        id: respuesta.data.usuario.id,
        dias: respuesta.data.usuario.diaLimite,
      });

      window.$usuario = respuesta.data.usuario.usuario;
    } catch (error) {

      console.log(error.response);
      guardarState({
        ...state,
        autenticado: false,
        usuario: null,
        cargando: false,
        token: null,
        nombre: null,
        id: null,
        dias: 0,
      });
    }
  };

  // Cuando el usuario inicia sesión
  const iniciarSesion = async (datos) => {

    try {
      const respuesta = await clienteAxios.post("/api/auth/prestador", datos);

      localStorage.setItem("token", respuesta.data.token);

      // Obtener el usuario
      prestadorAutenticado();
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.msg);
      mensajeAlerta(error.response.data.msg, "error");
      localStorage.removeItem("token");
      guardarState({
        ...state,
        autenticado: false,
        usuario: null,
        cargando: false,
        token: null,
        nombre: null,
        id: null,
        dias: 0,
      });
    }
  };

  // Cierra la sesión del usuario
  const cerrarSesion = () => {
    localStorage.removeItem("token");
    guardarState({
      ...state,
      autenticado: false,
      usuario: null,
      cargando: false,
      token: null,
      nombre: null,
      id: null,
      dias: 0,
  });
    //window.location.reload();
  };


  return (
    <Fragment>
      <Provider
        value={{
          token: state.token,
          autenticado: state.autenticado,
          usuario: state.usuario,
          cargando: state.cargando,
          state,
          iniciarSesion,
          prestadorAutenticado,
          cerrarSesion,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AuthProvider, Consumer as AuthConsumer, AuthContext };
