import React, { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from "./context/AlertContext";
import { ServiciosProvider } from "./context/ServiciosContext";
import { ConfiguracionProvider } from "./context/ConfiguracionContext";
import Barra from "./components/layout/Barra";
//import Footer from "./components/layout/Footer";
import RutaPrivada from "./components/rutas/RutaPrivada";
import Login from "./components/auth/Login";
import Logo from "./components/layout/Logo";
import Alertas from "./components/layout/configuracion/Alertas";
import Finalizados from "./components/layout/servicios/Finalizados";
//import Firebase from "./components/layout/Firebase";
//import firebase, { FirebaseContext } from "./firebase";

function App() {
  return (
    /*
    <FirebaseContext.Provider
      value={{
        firebase,
      }}
    >
    */
        <AlertProvider>
          <AuthProvider>
            <ServiciosProvider>
              <ConfiguracionProvider>
                <Router>
                  <Barra />
                  <Switch>
                    {/*<Route exact path="/firebase" component={Firebase} />*/}
                    <Route exact path="/" component={Logo} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/alertas" component={Alertas} />

                    <Route
                      exact
                      path="/serviciosfinalizados"
                      component={Finalizados}
                    />
                  </Switch>
                </Router>
              </ConfiguracionProvider>
            </ServiciosProvider>
          </AuthProvider>
        </AlertProvider>
      /*</FirebaseContext.Provider>*/
  );
}

export default App;
