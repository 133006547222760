//imports front
import React, { useState, useContext, useEffect, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import * as EmailValidator from "email-validator";
import { AuthContext } from "../../context/AuthContext";
import { AlertContext } from "../../context/AlertContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";

import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const { autenticado, iniciarSesion } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obtenerConfiguracion } = configuracionContext;

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const [usuario, guardarUsuario] = useState({
    email: "",
    password: "",
  });

  const [ip, guardarIp] = useState(null);

  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    obtenerConfiguracion();
  }, []);

  // extraer de usuario
  const { email, password } = usuario;

  const login = (e) => {
    e.preventDefault();
    //Validar

    if (email.trim() === "") {
      mensajeAlerta("El usuario es necesario !.", "error");
      return;
    }

    if (password.trim() === "") {
      mensajeAlerta("La clave es incorrecta !.", "error");
      return;
    }

    // Llamar al context
    iniciarSesion({ email, password, configuracion });
    setRedirect(true);
  };

  //console.log(configuracion);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) =>
              guardarUsuario({
                ...usuario,
                email: e.target.value,
              })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) =>
              guardarUsuario({
                ...usuario,
                password: e.target.value,
              })
            }
          />
          {/*
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recuerdame"
          />
          */}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={login}
          >
            Iniciar Sesión
          </Button>
        </form>
      </div>
      {redirect ? <Redirect to="/" /> : null}
    </Container>
  );
}
