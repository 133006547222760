import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";

const useStyles = makeStyles((theme) => ({
  option: {
    "&:hover": {
      backgroundColor: "#808080",
    },
  },

  /*
  inputRoot: {
    color: (props) => props.color,
    backgroundColor: "red",
    //color: "red"
  },
  */

  actionItemStyles: {
    color: "green",
    "&$selected": {
      color: "red",
    },
    "&:hover": {
      color: "#808080",
    },
  },
  // This is required for the '&$selected' selector to work
  selected: {},

  iconNavigation: {
    fontSize: "medium",
    "&:hover": {
      fontSize: "large",
    },
  },

  grid: {
    margin: 0,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 8,
  },
  textField: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontSize: "xx-small",
  },

  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(0),
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    size: "small",
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  select: {
    paddingTop: "6px",
  },
  tooltip: {
    fontSize: ".7em",
  },
}));

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.9)", // (default alpha is 0.38)
    },
  },
})(TextField);

export default function ConsultaServicio(props) {
  const classes = useStyles();
  const [notaVisible, setNotaVisible] = useState(false);
  const [nota, setNota] = useState(null);

  const serviciosContext = useContext(ServiciosContext);
  const {
    servicio,
    trasladosServicios,
    notas,
    guardarOpcionesMovil,
    openDrawer,
    drawer,
    eventoRealizado,
    setDrawer,
    setOpenDrawer,
    guardarEventoRealizado,
    agregarNota,
    obtenerNotas,
    obtenerTrasladosServicios,
  } = serviciosContext;

  const authContext = useContext(AuthContext);
  const { state } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion } = configuracionContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    //obtenerNotas({ servicioId: servicio.id });
    if (servicio && servicio !== null) {
      obtenerTrasladosServicios({ servicioId: servicio.id });
    }
  }, [servicio]);

  function formatDateTime(dateString) {
    var allDateTime = dateString.split(" ");

    var newDate = allDateTime[0];
    var newTime = allDateTime[1];
    return newDate + " " + newTime;
  }

  function formatDateTime2(dateString) {
    var allDate = dateString.replace("T", " ");
    allDate = allDate.replace(".", " ");
    allDate = allDate.split(" ");
    var thisDate = allDate[0].split("-");
    var thisTime = allDate[1].split(":");
    var newDate = [thisDate[2], thisDate[1], thisDate[0]].join("/");
    var newTime = [thisTime[0], thisTime[1]].join(":");
    return newDate + " " + newTime;
  }

  //console.log(servicio);

  return (
    <Fragment>
      <Grid
        className={classes.root}
        container
        spacing={0}
        style={{ padding: "0" }}
      >
        <Grid item xs={12} md={12}>
          <h5>
            <u>Afiliado</u>
          </h5>
        </Grid>
        <Grid item xs={12} md={6}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="titular"
            label="Titular"
            value={
              servicio
                ? servicio.titularId + " - " + servicio.nombreTitular
                : null
            }
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="id"
            label="ID Afiliado"
            value={servicio ? servicio.afiliadoId : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="dni"
            label="D.N.I."
            value={servicio ? servicio.numeroDocumento : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={5} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="nombre"
            label="Apellido y Nombre"
            value={servicio ? servicio.nombre : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={3} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="telefono"
            label="Teléfono"
            value={servicio ? servicio.telefono : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={1} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="edad"
            label="Edad"
            value={servicio ? servicio.edad : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={1} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="sexo"
            label="Sexo"
            value={servicio ? servicio.sexo : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={12} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="domicilio"
            label="Domicilio"
            value={servicio ? servicio.domicilio : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={7} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="plan"
            label="Plan"
            value={
              servicio ? servicio.plan + " (" + servicio.grupo + " )" : null
            }
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="antecedente"
            label="Antecedente"
            value={servicio ? servicio.antecedente : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="tratamiento"
            label="Tratamiento"
            value={servicio ? servicio.tratamiento : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="motivoConsulta"
            label="Motivo de consulta"
            value={servicio ? servicio.motivoConsulta : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={3} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="operadorEmisor"
            label="Operador emisor"
            value={servicio ? servicio.operadorEmisor : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={3} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="indicativoEmisor"
            label="Indic. emisor"
            value={servicio ? servicio.indicativoEmisor : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={4} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="obraSocial"
            label="Obra social"
            value={servicio ? servicio.obraSocial : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={3} className={classes.grid}>
          <DarkerDisabledTextField
            InputProps={{ style: { fontSize: "small" } }}
            InputLabelProps={{
              style: { color: "#2196F3", fontWeight: "bold" },
            }}
            id="nroObraSocial"
            label="Nº Obra social"
            value={servicio ? servicio.nroObraSocial : null}
            fullWidth
            size="small"
            className={classes.textField}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} md={2} className={classes.grid}>
          <FormControlLabel
            value="yes"
            control={
              <Checkbox
                id="covid"
                color="primary"
                checked={servicio ? servicio.covid : false}
              />
            }
            label={
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ color: "#2196F3", fontWeight: "bold" }}
              >
                {" "}
                Covid{" "}
              </Typography>
            }
            labelPlacement="start"
            fullWidth
            disabled={true}
          />
        </Grid>

        {servicio &&
        servicio.indicativo === null &&
        servicio.color === "AZUL" ? (
          <Fragment>
            <Grid item xs={12} md={12}>
              <h5>Traslado Programado</h5>
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="tipoMovil"
                label="Tipo de Móvil"
                value={servicio ? servicio.tipoMovil : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="espera"
                label="Espera"
                value={servicio ? servicio.espera : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <FormControlLabel
                value="yes"
                control={
                  <Checkbox
                    id="retorno"
                    color="primary"
                    checked={servicio ? servicio.retorno : false}
                  />
                }
                label={
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    style={{ color: "#2196F3", fontWeight: "bold" }}
                  >
                    {" "}
                    Retorno{" "}
                  </Typography>
                }
                labelPlacement="start"
                fullWidth
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={6} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="destino"
                label="Destino"
                value={servicio ? servicio.domicilioDestino : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="ftomadoAzul"
                label="Fecha Tomado"
                value={servicio ? servicio.ftomadoAzul : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="usuarioTomadoAzul"
                label="Usuario Tomado"
                value={servicio ? servicio.usuarioTomadoAzul : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
          </Fragment>
        ) : null}

        {servicio &&
        servicio.indicativo === null &&
        servicio.color === "NARANJA" ? (
          <Fragment>
            <Grid item xs={12} md={12}>
              <h5>Evento Programado</h5>
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="duracion"
                label="Duración"
                value={servicio ? servicio.duracion : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="cantidadMoviles"
                label="Cantidad Móviles"
                value={servicio ? servicio.cantidadMoviles : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="dotacion"
                label="Dotación"
                value={servicio ? servicio.dotacion : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="tipoEvento"
                label="Tipo de Evento"
                value={servicio ? servicio.tipoEvento : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="responsable"
                label="Responsable"
                value={servicio ? servicio.responsable : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="contacto"
                label="Contacto"
                value={servicio ? servicio.contacto : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="pedido"
                label="Pedido"
                value={servicio ? servicio.pedido : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="telefono"
                label="Teléfono"
                value={servicio ? servicio.telefono : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="cantidadPersonas"
                label="Cantidad de Personas"
                value={servicio ? servicio.cantidadPersonas : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={5} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="descripcion"
                label="Descripción"
                value={servicio ? servicio.descripcion : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="ftomadoNaranja"
                label="Fecha Tomado"
                value={servicio ? servicio.ftomadoNaranja : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="usuarioTomadoNaranja"
                label="Usuario Tomado"
                value={servicio ? servicio.usuarioTomadoNaranja : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
          </Fragment>
        ) : null}

        {servicio && servicio.indicativo ? (
          <Fragment>
            <Grid item xs={12} md={12}>
              <h5>
                <u>Servicio</u>
              </h5>
            </Grid>
            {/*
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="idServicio"
                label="ID Servicio"
                value={servicio ? servicio.id : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
              */}
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="indicativo"
                label={
                  configuracion.destinatario !== 2 ? "Indicativo" : "Ind. - Id"
                }
                value={
                  servicio
                    ? configuracion.destinatario !== 2
                      ? servicio.id
                      : servicio.indicativo + " - " + servicio.id
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={
                  servicio.colorInicio === "AZUL"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }
                    : servicio.colorInicio === "NARANJA"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "orange",
                          color: "black",
                        },
                      }
                    : servicio.colorInicio === "VERDE"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "green",
                          color: "white",
                        },
                      }
                    : servicio.colorInicio === "CELESTE"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "aqua",
                          color: "black",
                        },
                      }
                    : servicio.colorInicio === "AMARILLO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "yellow",
                          color: "black",
                        },
                      }
                    : servicio.colorInicio === "ROJO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "red",
                          color: "white",
                        },
                      }
                    : servicio.colorInicio === "BLANCO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "white",
                          color: "black",
                        },
                      }
                    : servicio.colorInicio === "NEGRO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "black",
                          color: "white",
                        },
                      }
                    : servicio.colorInicio === "FUCCIA"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "fucsia",
                          color: "black",
                        },
                      }
                    : null
                }
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="color"
                label="Color Inicio"
                value={servicio ? servicio.colorInicio : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={
                  servicio.color === "AZUL"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }
                    : servicio.color === "NARANJA"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "orange",
                          color: "black",
                        },
                      }
                    : servicio.color === "VERDE"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "green",
                          color: "white",
                        },
                      }
                    : servicio.color === "CELESTE"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "aqua",
                          color: "black",
                        },
                      }
                    : servicio.color === "AMARILLO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "yellow",
                          color: "black",
                        },
                      }
                    : servicio.color === "ROJO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "red",
                          color: "white",
                        },
                      }
                    : servicio.color === "BLANCO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "white",
                          color: "black",
                        },
                      }
                    : servicio.color === "NEGRO"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "black",
                          color: "white",
                        },
                      }
                    : servicio.color === "FUCCIA"
                    ? {
                        style: {
                          fontSize: "small",
                          backgroundColor: "fucsia",
                          color: "black",
                        },
                      }
                    : null
                }
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="color"
                label="Color Final"
                value={servicio ? servicio.color : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="coseguroAbonar"
                label="Coseg. Abonar"
                value={
                  servicio && servicio.coseguroAbonar
                    ? parseFloat(servicio.coseguroAbonar).toFixed(2)
                    : 0.0
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="coseguroAbonado"
                label="Coseg. Abonado"
                value={
                  servicio && servicio.coseguroAbonado
                    ? parseFloat(servicio.coseguroAbonado).toFixed(2)
                    : 0.0
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="diagnosticoId"
                label="Diagnóstico"
                value={servicio ? servicio.diagnostico : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="detalleDiagnostico"
                label="Detalle Diagnóstico"
                value={servicio ? servicio.detalleDiagnostico : null}
                fullWidth
                multiline
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <h4></h4>
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="tomado"
                label="Tomado"
                value={
                  servicio && servicio.tomado && servicio.usuarioTomado
                    ? formatDateTime(servicio.tomado) +
                      " " +
                      servicio.usuarioTomado
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="pasado"
                label="Pasado"
                value={
                  servicio && servicio.pasado && servicio.usuarioPasado
                    ? formatDateTime(servicio.pasado) +
                      " " +
                      servicio.usuarioPasado
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="salida"
                label="Salida"
                value={
                  servicio && servicio.salida && servicio.usuarioSalida
                    ? formatDateTime(servicio.salida) +
                      " " +
                      servicio.usuarioSalida
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="llegada"
                label="Llegada"
                value={
                  servicio && servicio.llegada && servicio.usuarioLlegada
                    ? formatDateTime(servicio.llegada) +
                      " " +
                      servicio.usuarioLlegada
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="fin"
                label="Fin"
                value={
                  servicio && servicio.fin && servicio.usuarioFin
                    ? formatDateTime(servicio.fin) + " " + servicio.usuarioFin
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6} md={3} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="anulado"
                label="Anulado"
                value={
                  servicio && servicio.anulado && servicio.usuarioAnulado
                    ? formatDateTime(servicio.anulado) +
                      " " +
                      servicio.usuarioAnulado
                    : null
                }
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <h5>
                <u>Tripulación</u>
              </h5>
            </Grid>

            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="medico"
                label="Médico"
                value={servicio ? servicio.medico : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="chofer"
                label="Chofer"
                value={servicio ? servicio.chofer : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="enfermero"
                label="Enfermero"
                value={servicio ? servicio.enfermero : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="movil"
                label="Móvil"
                value={servicio ? servicio.movilId : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="base"
                label="Base"
                value={servicio ? servicio.base : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} md={2} className={classes.grid}>
              <DarkerDisabledTextField
                InputProps={{ style: { fontSize: "xx-small" } }}
                InputLabelProps={{
                  style: { color: "#2196F3", fontWeight: "bold" },
                }}
                id="telefonoTripulacion"
                label="Teléfono"
                value={servicio ? servicio.telefonoTripulacion : null}
                fullWidth
                size="small"
                className={classes.textField}
                disabled={true}
              />
            </Grid>
            {servicio &&
            servicio.traslados !== undefined &&
            servicio.traslados !== null ? (
              <Fragment>
                <Grid item xs={12} md={12}>
                  <h5>
                    <u>Traslados Programados</u>
                  </h5>
                </Grid>
                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="tipoMovil"
                    label="Tipo de Móvil"
                    value={
                      servicio && servicio.traslados !== null
                        ? servicio.traslados.tiposMoviles.nombre
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={12} md={4} style={{ marginTop: ".8vw" }}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="prestador"
                    label="Prestador a Asignar"
                    value={
                      servicio && servicio.traslados !== null
                        ? servicio.traslados.prestador
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={1} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="espera"
                    label="Espera"
                    value={
                      servicio && servicio.traslados !== null
                        ? servicio.traslados.espera
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} md={2} className={classes.grid}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="retorno"
                        color="primary"
                        checked={
                          servicio && servicio.traslados !== null
                            ? servicio.traslados.retorno
                            : false
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{ color: "#2196F3", fontWeight: "bold" }}
                      >
                        {" "}
                        Retorno{" "}
                      </Typography>
                    }
                    labelPlacement="start"
                    fullWidth
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={6} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="destino"
                    label="Destino"
                    value={
                      servicio && servicio.traslados !== null
                        ? servicio.traslados.destino
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>
              </Fragment>
            ) : null}
            {servicio &&
            servicio.eventos !== undefined &&
            servicio.eventos !== null ? (
              <Fragment>
                <Grid item xs={12} md={12}>
                  <h5>
                    <u>Eventos</u>
                  </h5>
                </Grid>
                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="duracion"
                    label="Duración"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.duracion
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={2} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="cantidadMoviles"
                    label="Cant. Móviles"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.cantidadMoviles
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={4} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="dotacion"
                    label="Dotación"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.dotacion
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="tipoEvento"
                    label="Tipo de Evento"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.tipoEvento
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="responsable"
                    label="Responsable"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.responsable
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="contacto"
                    label="Contacto"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.contacto
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="pedido"
                    label="Pedido"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.pedido
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} md={3} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="cantidadPersonas"
                    label="Cantidad de Personas"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.cantidadPersonas
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} md={12} className={classes.grid}>
                  <DarkerDisabledTextField
                    InputProps={{ style: { fontSize: "small" } }}
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="descripcion"
                    label="Descripción"
                    value={
                      servicio && servicio.eventos !== null
                        ? servicio.eventos.descripcion
                        : null
                    }
                    fullWidth
                    size="small"
                    className={classes.textField}
                    disabled={true}
                  />
                </Grid>
              </Fragment>
            ) : null}
            {trasladosServicios && trasladosServicios.length > 0 ? (
              <Fragment>
                <Grid item xs={12} md={12}>
                  <h5>
                    <u>Traslados del Servicio</u>
                  </h5>
                </Grid>

                {trasladosServicios.map((element) => {
                  return (
                    <Fragment>
                      <Grid item xs={12} md={3} className={classes.grid}>
                        <DarkerDisabledTextField
                          InputProps={{ style: { fontSize: "xx-small" } }}
                          InputLabelProps={{
                            style: { color: "#2196F3", fontWeight: "bold" },
                          }}
                          id="salida"
                          label="Salida"
                          value={
                            formatDateTime2(element.salida) +
                            " " +
                            element.usuarioSalida
                          }
                          fullWidth
                          size="small"
                          className={classes.textField}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.grid}>
                        <DarkerDisabledTextField
                          InputProps={{ style: { fontSize: "small" } }}
                          InputLabelProps={{
                            style: { color: "#2196F3", fontWeight: "bold" },
                          }}
                          id="destino"
                          label="Destino"
                          value={element.destino}
                          fullWidth
                          size="small"
                          className={classes.textField}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.grid}>
                        <DarkerDisabledTextField
                          InputProps={{ style: { fontSize: "xx-small" } }}
                          InputLabelProps={{
                            style: { color: "#2196F3", fontWeight: "bold" },
                          }}
                          id="llegada"
                          label="Llegada"
                          value={
                            element.llegada !== null
                              ? formatDateTime2(element.llegada) +
                                " " +
                                element.usuarioLlegada
                              : null
                          }
                          fullWidth
                          size="small"
                          className={classes.textField}
                          disabled={true}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
              </Fragment>
            ) : null}
          </Fragment>
        ) : null}
      </Grid>
    </Fragment>
  );
}
