import React, { useState, Fragment, useRef } from "react";
import { createContext } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { Toast } from "primereact/toast";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertContext = createContext();
const { Provider, Consumer } = AlertContext;

function AlertProvider({ children }) {
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const toastTR = useRef(null);
  const toastTL = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({
      ...state,
      open: false,
    });
  };

  const mensajeAlerta = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setState({
      ...state,
      open: true,
    });
  };

  const mensajeAlertaTR = (summary, message, severity) => {
    toastTR.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };

  const mensajeAlertaTL = (summary, message, severity) => {
    toastTL.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };


  return (
    <Fragment>
      <Toast ref={toastTR} />
      <Toast ref={toastTL} position="top-left"></Toast>
      <Provider
        value={{
          mensajeAlerta,
          mensajeAlertaTR,
          mensajeAlertaTL,
        }}
      >
        {children}
      </Provider>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export { AlertProvider, Consumer as AlertConsumer, AlertContext };
