import React, { useEffect, useContext, Fragment, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import logo1 from "../../assets/images/mediclogoblanco.png";
import logo2 from "../../assets/images/cardiologo.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import MenuPrincipal from "./Menu";
import { Redirect } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/People";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "5vw",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    paddingLeft: "20vw",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    paddingLeft: "20vw",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        backgroundColor: "#ffff00",
        color: "#000000",
        height: "32px",
        minHeight: "32px",
        "@media(min-width:600px)": {
          minHeight: "48px",
        },
      },
    },
  },
});

function Barra(props) {
  const authContext = useContext(AuthContext);
  const {
    state,
    autenticado,
    prestadorAutenticado,
    cerrarSesion,
  } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obtenerConfiguracion } = configuracionContext;

  const [redirect, setRedirect] = useState(false);
  const [loginMedicos, setLoginMedicos] = useState(false);
  const [color, setColor] = useState(null);

  useEffect(() => {
    obtenerConfiguracion();
  }, []);

  useEffect(() => {
    prestadorAutenticado();
  }, [state.id]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const cerrar = () => {
    handleMenuClose();
    cerrarSesion();
    setRedirect(true);
    setTimeout(() => {
      window.location.reload("/");
    }, 1500);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/*
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      */}

      {state && state.autenticado !== true ? (
        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
          Iniciar Sesión
        </MenuItem>
      ) : (
        <MenuItem onClick={cerrar}>Cerrar Sesión</MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/*
      <MenuItem>
        <IconButton aria-label="medicos" color="inherit">
          <PeopleIcon />
        </IconButton>
        <p>Médicos</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={5} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      */}

      {state && state.autenticado !== true ? (
        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
          Iniciar Sesión
        </MenuItem>
      ) : (
        <MenuItem onClick={cerrar}>Cerrar Sesión</MenuItem>
      )}
    </Menu>
  );

  const loginMedico = () => {
    setLoginMedicos(true);
  };

  const prueba = () => {
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 1);
  };

  //console.log(configuracion)

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          backgroundColor:
            configuracion && configuracion.destinatario === 1
              ? "#D8261C"
              : configuracion && configuracion.destinatario === 2
              ? "#E36D00"
              : "#0000",
        }}
      >
        <Toolbar>
          {state && state.autenticado === true ? (
            <Fragment>
              <MenuPrincipal />
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            </Fragment>
          ) : null}

          {configuracion && configuracion.destinatario === 1 ? (
            <Fragment>
              <a href="/">
                <img
                  src={logo1}
                  alt="Logo"
                  height="50"
                  style={{ marginLeft: "2em", marginTop: "-0.3em" }}
                />
              </a>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ color: "black", fontWeight: "bold" }}
              >
                {" "}
                Prestadores{" "}
              </Typography>
            </Fragment>
          ) : null}
          {configuracion && configuracion.destinatario === 2 ? (
            <Fragment>
              <a href="/">
                <img
                  src={logo2}
                  alt="Logo"
                  height="50"
                  style={{ marginLeft: "2em", marginTop: "0.2em" }}
                />
              </a>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{ color: "black", fontWeight: "bold" }}
              >
                {" "}
                Prestadores{" "}
              </Typography>
            </Fragment>
          ) : null}

          {/*
          <a href="#" onClick={prueba}>
            Menú
          </a>
          */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: ".7vw" }}>
              {state.autenticado ? state.nombre : null}
            </div>
            {/*
            <IconButton aria-label="medicos" color="inherit" onClick={loginMedico}>
              <PeopleIcon />
            </IconButton>
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="primary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <div style={{ marginTop: "2vw" }}>
              {state.autenticado ? state.nombre : null}
            </div>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {redirect ? <Redirect to="/" /> : null}
      {/*{loginMedicos ? <Redirect to="/loginmedicos" /> : null}*/}
    </div>
  );
}

export default Barra;
