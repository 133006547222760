import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import NumberFormat from "react-number-format";
import { selectedRowsCountSelector } from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { saveAs } from "file-saver";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";

const useStyles = makeStyles((theme) => ({
  col3: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "3%",
    },
  },

  col5: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "5%",
    },
  },
  col10: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "10%",
    },
  },
  col12: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "12%",
    },
  },
  col15: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "15%",
    },
  },
  col20: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
  },
}));

const agrupar = [
  {
    id: 1,
    nombre: "Base",
  },
  {
    id: 2,
    nombre: "ColorInicial",
  },
  {
    id: 3,
    nombre: "ColorFinal",
  },
  {
    id: 4,
    nombre: "Antecedente",
  },
  {
    id: 5,
    nombre: "Tratamiento",
  },
  {
    id: 6,
    nombre: "MotivoConsulta",
  },
  {
    id: 7,
    nombre: "Diagnostico",
  },
  {
    id: 8,
    nombre: "IDTitular",
  },
  {
    id: 9,
    nombre: "Documento",
  },
];

addLocale("es", {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  monthNamesShort: [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ],
  today: "Hoy",
  clear: "Claro",
});

export default function Finalizados(props) {
  const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [visiblePeriodos, setVisiblePeriodos] = useState(false);
  const [periodo, setPeriodo] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [visibleModificaciones, setVisibleModificaciones] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [servicioOriginal, guardarServicioOriginal] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [progress, setProgress] = useState(false);
  const [indicativo, setIndicativo] = useState(null);
  const [dni, setDni] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const loaded = useRef(false);
  const [center, setCenter] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [selected, setSelected] = useState(null);
  const [map, setMap] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [value2, setValue2] = useState(null);
  const [open, setOpen] = useState(false);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado, autenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    motivosConsultas,
    configuracion,
    diagnosticos,
    usuarios,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerMotivosConsultas,
    obtenerDiagnosticos,
    obtenerUsuarios,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    servicioModificado,
    filtrosListadosServicios,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizadosPrestadores,
    obtenerNotas,
    guardarServicio,
    uploadFilesPrestadores,
    guardarFiltrosListadosServicios,
    guardarServicioModificado,
    modificarServicioFinalizado,
    obtenerServicioFinalizado,
    eliminarHistoriaClinica,
  } = serviciosContext;

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const periodos = (e) => {
    setVisiblePeriodos(true);
  };

  const exitClick = (e) => {
    props.history.push(`/menu`);
  };

  const onHidePeriodos = (e) => {
    setVisiblePeriodos(false);
  };

  const onHideUpload = (e) => {
    setVisibleUpload(false);
  };

  const aceptarPeriodos = (e) => {
    e.preventDefault();
    //Validar

    if (
      !periodo ||
      periodo === undefined ||
      periodo === null ||
      periodo === ""
    ) {
      mensajeAlerta("Período es necesario !.", "error");
      return;
    }


    setProgress(true);

    // Llamar al context
    obtenerServiciosFinalizadosPrestadores({
      id: state.id,
      periodo,
      dias: state.dias,
    });
    onHidePeriodos();
    setProgress(false)
  };

  const accesoDenegado = () => {
    mensajeAlerta("No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">
          {configuracion.destinatario !== 2 ? "Indicativo" : "Ind. - Id"}
        </span>
        {configuracion.destinatario !== 2 ? rowData.id : rowData.indicativoId}
      </Fragment>
    );
  };

  const indicativoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Indicativo</span>
        {rowData.indicativo}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const colorInicialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Inicial</span>
        {rowData.colorInicio}
      </Fragment>
    );
  };

  const colorFinalTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Final</span>
        {rowData.color}
      </Fragment>
    );
  };

  const titularIdTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Socio Titular</span>
        {rowData.titularId}
      </Fragment>
    );
  };

  const nombreTitularTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre Titular</span>
        {rowData.nombreTitular}
      </Fragment>
    );
  };

  const TomadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tomado</span>
        {rowData.tomado}
      </Fragment>
    );
  };

  const PasadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pasado</span>
        {rowData.pasado}
      </Fragment>
    );
  };

  const SalidaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {rowData.salida}
      </Fragment>
    );
  };

  const LlegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const FinTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fin</span>
        {rowData.fin}
      </Fragment>
    );
  };

  const MovilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId}
      </Fragment>
    );
  };

  const TripulacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tripulación</span>
        {rowData.tripulacionId}
      </Fragment>
    );
  };

  const DomicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const DocumentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  function opcionNo() {
    toast.current.clear();
  }


  const opcionSiEliminar = (id) => {
    eliminarHistoriaClinica({id})
    opcionNo()

    setTimeout(() => {
      obtenerServiciosFinalizadosPrestadores({
        id: state.id,
        periodo,
        dias: state.dias,
      });
    }, 3000);


  }



  const eliminarClick = (id) => {

    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>{`Confirma eliminar Historia Clínica del id ${id} ?`}</h4>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) =>
                  opcionSiEliminar(id)
                }
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={opcionNo}
              />
            </div>
          </div>
        </div>
      ),
    });

  }




  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "despachos",
    });
    //setVisible(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={() => {
            consultaSocio(rowData);
            //obtenerNotas({ servicioId: rowData.id });
            setVisible(true);
          }}
          tooltip="Datos del servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: classes.tooltip,
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
            color: rowData.hcAdjunto === null ? "black" : "red",
          }}
          icon="pi pi-file"
          className="p-button-text"
          onClick={
            rowData.hcAdjunto === null
              ? () => {
                  setServicio(rowData);
                  subirAdjunto();
                }
              : () => window.open(rowData.hcAdjunto, "_blank")
          }
          //disabled={rowData.hcAdjunto === null}
          tooltip="H.C. Adjunto"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: classes.tooltip,
          }}
        />

        <Button
          style={{
            margin: 0,
            padding: 0,
            color: rowData.hcAdjunto === null ? "black" : "red",
          }}
          icon="pi pi-trash"
          className="p-button-text"
          onClick={
            rowData.hcAdjunto !== null
              ? () => {
                  eliminarClick(rowData.id);
                }
              : null
          }
          disabled={rowData.hcAdjunto === null}
          tooltip="H.C. Eliminar"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: classes.tooltip,
          }}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, serviciosFinalizados);
        doc.save("serviciosFinalizados.pdf");
      });
    });
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "serviciosFinalizados");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        {/*
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        */}
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Servicios Finalizados"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderPeriodos = () => {
    return (
      <div>
        <Tag value="Servicios Finalizados"></Tag>
      </div>
    );
  };

  const renderHeaderUpload = () => {
    return (
      <div>
        <Tag value="Upload de Archivos"></Tag>
      </div>
    );
  };

  const renderFooterPeriodos = () => {
    return (
      <div>
        <ButtonMui
          onClick={aceptarPeriodos}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHidePeriodos}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setSelectedRow(null);
    setVisible(false);
  };

  const subirAdjunto = () => {
    setVisibleUpload(true);
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("servicioId", servicio.id);

    uploadFilesPrestadores(formData);
    setVisibleUpload(false);
    setFile(null);
    setFilename(null);
    setTimeout(() => {
      obtenerServiciosFinalizadosPrestadores({
        id: state.id,
        periodo,
        dias: state.dias,
      });
      onHidePeriodos();
    }, 3000);
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag value="Modificaciones del Servicio"></Tag>
      </div>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  //console.log(state);

  return (
    <Fragment>
      <Toast ref={toast} />
      {state && state.autenticado === true ? (
        <Fragment>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ align: "center", margin: "1em" }}
          >
            <Button
              icon="pi pi-calendar"
              tooltip="Período"
              className="p-button-rounded p-button-help"
              style={{ marginLeft: "0em" }}
              onClick={periodos}
            />
            <Button
              icon="pi pi-times"
              tooltip="Cerrar"
              className="p-button-rounded p-button-primary"
              style={{ marginLeft: "1em" }}
              onClick={exitClick}
            />
            {progress && !serviciosFinalizados ? (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            ) : null}
            <div className="datatable-responsive-demo">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable
                  value={serviciosFinalizados}
                  selection={selectedRow}
                  onSelectionChange={(e) => {
                    setSelectedRow(e.value);
                    //guardarServicio(null);
                  }}
                  selectionMode="single"
                  dataKey="id"
                  header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "xx-small" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field={"indicativoId"}
                    header={"Ind. - Id"}
                    className={classes.col5}
                    body={idTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombre"
                    header="Nombre"
                    className={classes.col10}
                    body={nombreTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="colorInicio"
                    header="Color Inicial"
                    className={classes.col3}
                    body={colorInicialTemplate}
                    sortable
                    //filter
                    //filterPlaceholder="Color"
                  ></Column>
                  <Column
                    field="color"
                    header="Color Final"
                    className={classes.col3}
                    body={colorFinalTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="titularId"
                    header="Socio Titular"
                    className={classes.col5}
                    body={titularIdTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombreTitular"
                    header="Nombre Titular"
                    className={classes.col10}
                    body={nombreTitularTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tomado"
                    header="Tomado"
                    className={classes.col5}
                    body={TomadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="pasado"
                    header="Pasado"
                    className={classes.col5}
                    body={PasadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="salida"
                    header="Salida"
                    className={classes.col5}
                    body={SalidaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="llegada"
                    header="Llegada"
                    className={classes.col5}
                    body={LlegadaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="fin"
                    header="Fin"
                    className={classes.col5}
                    body={FinTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="movilId"
                    header="Móvil"
                    className={classes.col5}
                    body={MovilTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tripulacionId"
                    header="Tripulación"
                    className={classes.col5}
                    body={TripulacionTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    className={classes.col20}
                    body={DomicilioTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="numeroDocumento"
                    header="Documento"
                    className={classes.col5}
                    body={DocumentoTemplate}
                    sortable
                  ></Column>
                  <Column
                    className={classes.col12}
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Grid>

          <Dialog
            header={renderHeader}
            visible={visible}
            className="p-dialog-despacho"
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHide()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "100vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="top-right"
          >
            <Grid container spacing={1} style={{ padding: "0" }}>
              <Grid item xs={12} md={8}>
                <ScrollPanel style={{ width: "100%", height: "100%" }}>
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <ConsultaServicio />
                  </div>
                </ScrollPanel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ScrollPanel
                  style={{ width: "100%", height: "100%" }}
                  className="custombar1"
                >
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <NotasServicio />
                  </div>
                </ScrollPanel>
              </Grid>
            </Grid>
          </Dialog>
          <Dialog
            header={renderHeaderUpload}
            visible={visibleUpload}
            className="p-dialog-despacho"
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHideUpload()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "55vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="center-left"
          >
            <Grid
              container
              spacing={3}
              style={{ padding: "0", marginTop: "1vw" }}
            >
              <Grid item xs={12} md={12}>
                <div className="file-select" id="src-file1">
                  <input
                    type="file"
                    name="src-file1"
                    aria-label="Archivo"
                    accept="image/*,.pdf"
                    onChange={onChange}
                  />
                </div>

                <div style={{ marginLeft: "1vw", display: "inline-block" }}>
                  <label htmlFor="src-file1">{filename}</label>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="button"
                  icon="pi pi-upload"
                  onClick={uploadFile}
                  className="p-button-warning p-mr-2"
                  style={{ marginRight: "1em" }}
                  label="Upload"
                />
              </Grid>
            </Grid>
          </Dialog>

          <Dialog
            header={renderHeaderPeriodos()}
            visible={visiblePeriodos}
            closable={false}
            footer={renderFooterPeriodos()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={12} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={6}>
                <label htmlFor="monthpicker">Período</label>
                <Calendar
                  id="monthpicker"
                  value={periodo}
                  onChange={(e) => {
                    setPeriodo(e.target.value);
                  }}
                  view="month"
                  dateFormat="mm/yy"
                  locale="es"
                  yearNavigator
                  yearRange="2022:2030"
                  style={{ marginLeft: "1vw" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
              <Typography variant="caption" display="block" gutterBottom>
                  {`Dia límite de presentación ${state.dias} del mes siguiente`}
                </Typography>
              </Grid>
            </Grid>
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
