import React, { Fragment, useContext, useEffect, useState } from "react";
import $ from "jquery";
//import "../../assets/css/demo.css";
//import "./dist/hc-offcanvas-nav.carbon.css";
import "../../assets/css/hc-offcanvas-nav.css";
import hcOffcanvasNav from "hc-offcanvas-nav";
//import hcOffcanvasNav from "../../dist/hc-offcanvas-nav";
import { AuthContext } from "../../context/AuthContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import AirportShuttleOutlinedIcon from "@material-ui/icons/AirportShuttleOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import AlarmIcon from "@material-ui/icons/Alarm";
import TuneOutlinedIcon from "@material-ui/icons/TuneOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import FlagIcon from "@material-ui/icons/Flag";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BuildIcon from "@material-ui/icons/Build";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Spinner from "./Spinner";

function Menu() {
  const authContext = useContext(AuthContext);
  const {
    autenticado,
    usuario,
    menu,
    prestadorAutenticado,
    guardarMenu,
  } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obtenerConfiguracion } = configuracionContext;

  const [band, setBand] = useState(true);

  useEffect(() => {
    // Menu Trigger

    prestadorAutenticado();

    $(document).ready(function($) {
      var Nav = new hcOffcanvasNav("#main-nav", {
        disableAt: false,
        customToggle: ".toggle",
        levelSpacing: 40,
        navTitle: "Home",
        levelTitles: true,
        levelTitleAsBack: true,
        pushContent: "#container",
        labelClose: false,
        position: "left",
        levelOpen: "overlap",
      });

      // add new items to original nav
      $("#main-nav")
        .find("li.add")
        .children("a")
        .on("click", function() {
          var $this = $(this);
          var $li = $this.parent();
          var items = eval("(" + $this.attr("data-add") + ")");
          $li.before('<li class="new"><a href="#">' + items[0] + "</a></li>");
          items.shift();

          if (!items.length) {
            $li.remove();
          } else {
            $this.attr("data-add", JSON.stringify(items));
          }

          Nav.update(true); // update DOM
        });

      //Nav.open(2,2);
    });
  }, []);

  useEffect(() => {
    obtenerConfiguracion();
  }, []);


  return (
    <Fragment>
      {band ? (
        <div id="container">
          <div className="wrapper cf">
            <nav id="main-nav">
              <ul className="first-nav">
                <li>
                  <a href="/serviciosfinalizados">
                    <AirportShuttleOutlinedIcon
                      style={{ fontSize: "medium" }}
                    />{" "}
                    Servicios
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <a className="toggle" href="#" style={{ marginLeft: "1.2em" }}>
            {/*<span />*/}
          </a>
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}

export default Menu;
