import React, { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { AlertContext } from "../../../context/AlertContext";
import { Link, Redirect } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Spinner from "../../layout/Spinner";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Alertas(props) {
  const classes = useStyles();

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    alertas,
    obtenerAcceso,
    obtenerAlertas,
    guardarAlertas,
    actualizarAlertas,
  } = configuracionContext;

  const [redirect, setRedirect] = useState(false);

  // extraer de usuario

  useEffect(() => {
    usuarioAutenticado();
  }, [state.id]);

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Alertas" });
    }

    obtenerAlertas();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const aceptarClick = (e) => {
    e.preventDefault();

    actualizarAlertas(alertas);
  };

  const exitClick = (e) => {
    props.history.push(`/menu`);
  };

  const accesoDenegado = () => {
    mensajeAlerta("No tiene permiso para acceder a esta pantalla !.", "error");
    exitClick();
    return;
  };

  //console.log(alertas);

  return (
    <Fragment>
      {statusAcceso === 0 || statusAcceso === 400 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <CssBaseline />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <DialogTitle id="id">
                <Box display="flex" alignItems="center" margin-top="0.1em">
                  <Box flexGrow={1}>{"Alertas"}</Box>
                  <Box>
                    <IconButton component={Link} to="/">
                      <CloseIcon style={{ color: "#3F51B5" }} />
                    </IconButton>
                  </Box>
                </Box>
              </DialogTitle>

              <Grid container spacing={3}>
                <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="alertaLlegada"
                        color="primary"
                        checked={alertas ? alertas.alertaLlegada : false}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarAlertas({
                            ...alertas,
                            alertaLlegada: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Mostrar carteles de llegada de servicios en estado de alerta"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="demoraLlegada"
                        color="primary"
                        checked={alertas ? alertas.demoraLlegada : false}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarAlertas({
                            ...alertas,
                            demoraLlegada: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Mostrar carteles de llegada de servicios en estado de demora"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="alertaSalida"
                        color="primary"
                        checked={alertas ? alertas.alertaSalida : false}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarAlertas({
                            ...alertas,
                            alertaSalida: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Mostrar carteles de servicios pasados en estado de alerta"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="demoraSalida"
                        color="primary"
                        checked={alertas ? alertas.demoraSalida : false}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarAlertas({
                            ...alertas,
                            demoraSalida: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Mostrar carteles de servicios pasados en estado de demora"
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12} md={12} style={{ paddingLeft: 0 }}>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Checkbox
                        id="pendiente"
                        color="primary"
                        checked={alertas ? alertas.pendiente : false}
                        //onKeyDown={handleEnter}
                        onChange={(e) =>
                          guardarAlertas({
                            ...alertas,
                            pendiente: e.target.checked,
                          })
                        }
                      />
                    }
                    label="Mostrar carteles de servicios pendientes de asignar móvil"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "green" }}
                    onClick={aceptarClick}
                  >
                    Aceptar
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#D8261C" }}
                    onClick={exitClick}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </main>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
