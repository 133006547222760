import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import logo3 from "../../assets/images/ambulanceapp.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    height: "30px",
    width: "100%",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      align: "center",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "25vw",

    },
    [theme.breakpoints.up("sm")]: {
      align: "center",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "1.5vw",

    },
  },

}));

export default function Logo() {
  const classes = useStyles();

  return (
    <div className = {classes.logo}
    >
      <img
        src={logo3}
        alt="Logo"
        width="40%"
        display="block"
        margin="auto"

        //style={{ marginLeft: "2em", marginTop: "-0.3em" }}
      />
    </div>
  );
}
