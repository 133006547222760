import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { AuthContext } from "../../../context/AuthContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { OrderList } from "primereact/orderlist";
import "../../../assets/css/OrderListDemo.css";

const useStyles = makeStyles((theme) => ({
  option: {
    "&:hover": {
      backgroundColor: "#808080",
    },
  },

  /*
  inputRoot: {
    color: (props) => props.color,
    backgroundColor: "red",
    //color: "red"
  },
  */

  actionItemStyles: {
    color: "green",
    "&$selected": {
      color: "red",
    },
    "&:hover": {
      color: "#808080",
    },
  },
  // This is required for the '&$selected' selector to work
  selected: {},

  iconNavigation: {
    fontSize: "medium",
    "&:hover": {
      fontSize: "large",
    },
  },

  grid: {
    margin: 0,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    fontSize: 8,
  },
  textField: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontSize: "xx-small",
  },

  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(0),
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    size: "small",
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  select: {
    paddingTop: "6px",
  },
  tooltip: {
    fontSize: ".7em",
  },
}));

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.9)", // (default alpha is 0.38)
    },
  },
})(TextField);

export default function NotasServicio(props) {
  const classes = useStyles();

  const serviciosContext = useContext(ServiciosContext);
  const {
    servicio,
    notas,
    nota,
    notaVisible,
    guardarOpcionesMovil,
    openDrawer,
    drawer,
    eventoRealizado,
    setDrawer,
    setOpenDrawer,
    guardarEventoRealizado,
    agregarNota,
    agregarNotaPendiente,
    obtenerNotas,
    obtenerNotasPendientes,
    setNota,
    setNotaVisible,
  } = serviciosContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  //const classes = useStyles({color: afiliado ? afiliado.motivoConsultaColor : null });

  useEffect(() => {
    if (servicio && servicio !== null && servicio.origen === "despachos") {
      obtenerNotas({ servicioId: servicio.id });
    }
    if (servicio && servicio !== null && servicio.origen === "pendientes") {
      obtenerNotasPendientes({ servicioId: servicio.id });
    }
  }, [servicio]);

  function formatDateTime(dateString) {
    var allDateTime = dateString.split(" ");

    var newDate = allDateTime[0];
    var newTime = allDateTime[1];
    return newDate + " " + newTime;
  }

  const renderHeader = () => {
    return (
      <div>
        <Tag value="Notas del Servicio"></Tag>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => {
            if (servicio.origen === "despachos") {
              agregarNota({
                nota,
                servicioId: servicio.id,
                usuario: state.usuario.usuario,
              });
              setNotaVisible(false);
              setTimeout(() => {
                obtenerNotas({ servicioId: servicio.id });
              }, 2000);
            }
            if (servicio.origen === "pendientes") {
              agregarNotaPendiente({
                nota,
                servicioId: servicio.id,
                usuario: state.usuario.usuario,
              });
              setNotaVisible(false);
              setTimeout(() => {
                obtenerNotasPendientes({ servicioId: servicio.id });
              }, 2000);
            }
          }}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />
      </div>
    );
  };

  const onHide = (e) => {
    setNotaVisible(false);
  };

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <h5 className="p-mb-2 p-width">{item.nota}</h5>
          <h6 className="p-mb-2 p-width">
            {item.fecha}
            {"  "}
            {item.usuario}
          </h6>
        </div>
      </div>
    );
  };

  //console.log(servicio);

  return (
    servicio ?
    <Fragment>
      <Grid container spacing={0} style={{ padding: "0" }}>
        <Grid item xs={12} md={12}>
          <div className="orderlist-demo">
            <div className="card">
              {notas ? (
                <Fragment>
                  <Button
                    style={{
                      marginTop: "1em",
                      marginBottom: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      float: "right",
                      marginRight: 0,
                      marginLeft: "1vw",
                    }}
                    icon="pi pi-plus-circle"
                    className="p-button-success p-button-rounded"
                    onClick={() => {
                      setNota(null);
                      setNotaVisible(true);
                    }}
                    tooltip="Agregar Nota"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                      className: classes.tooltip,
                    }}
                  />

                  <OrderList
                    value={notas}
                    dragdrop
                    listStyle={{ height: "auto" }}
                    dataKey="id"
                    itemTemplate={itemTemplate}
                    header={"Notas Id. " + servicio.id}
                    style={{ marginRight: "0vw" }}
                    //onChange={(e) => setProducts(e.value)}
                  ></OrderList>
                </Fragment>
              ) : null}
            </div>
          </div>
        </Grid>
        {/*    
        <Grid item xs={12} md={12}>
          {notas
            ? notas.map((data) => (
                <div>
                  <p>
                    {"- "}
                    {data.nota}
                    {" - "}
                    {data.fecha}
                    {" - "}
                    {data.usuario}
                  </p>
                </div>
              ))
            : null}
        </Grid>
            */}
      </Grid>
      <div className="dialog-demo">
        <div className="card">
          <Dialog
            header={renderHeader}
            visible={notaVisible}
            icon="pi pi-external-link"
            closable={false}
            onHide={() => onHide()}
            footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "40vw",
              paddingBottom: "0",
              overflowY: "visible",
            }}
            position="center"
          >
            <InputTextarea
              rows={5}
              cols={60}
              value={nota}
              onChange={(e) => setNota(e.target.value)}
            />{" "}
            <br />
          </Dialog>
        </div>
      </div>
    </Fragment>
    : null
  );
}
